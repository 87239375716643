import { isAnyVacationRentalsCategory } from '@kijiji/category'
import { type Category } from '@kijiji/generated/graphql-types'

import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'
import { type BreadcrumbsProps } from '@/ui/molecules/breadcrumbs'

type BreadcrumbCategory = Pick<
  Category,
  'id' | 'localizedName' | 'parentId' | 'searchSeoUrl' | 'categoryPaths'
>

type GetSrpBreadcrumbProps = {
  activeCategory?: BreadcrumbCategory
  adId?: number
  crumbs?: BreadcrumbsProps['crumbs']
  keywords?: string | null
  locationName?: string
  routeLocale: RouteLocale
}

/**
 * Get the breadcrumbs for the SRP page
 *
 * @param category
 * @param crumbs
 */
const addCategoryToBreadcrumbs = (
  category: Category,
  routeLocale: RouteLocale,
  crumbs: BreadcrumbsProps['crumbs'],
  locationName?: string
) => {
  let label = `${category.localizedName}`

  /**
   * Vacation rental sub categories are already a location, they don't need the location on the label
   * */
  if (!isAnyVacationRentalsCategory(category.id) && locationName) {
    label += routeLocale === ROUTE_LOCALE.fr ? ` à ${locationName}` : ` for ${locationName}`
  }

  crumbs.splice(1, 0, { label, href: category.searchSeoUrl ?? '' })
}

/**
 * Add parent category to the breadcrumbs
 *
 * @param activeCategory
 * @param categoryPath
 * @param crumbs
 * @returns
 */
const addParentCategoryToBreadcrumbs = ({
  activeCategory,
  crumbs = [],

  routeLocale,
}: Pick<Required<GetSrpBreadcrumbProps>, 'activeCategory' | 'crumbs' | 'routeLocale'>) => {
  const { id, localizedName, parentId, searchSeoUrl } =
    activeCategory.categoryPaths?.find((item) => item?.id === activeCategory.parentId) ?? {}

  if (!id || !localizedName) return

  const remainingPath = activeCategory.categoryPaths?.filter(
    (item) => item?.id !== activeCategory.id
  )
  const parent: BreadcrumbCategory = {
    id,
    localizedName,
    parentId,
    searchSeoUrl,
    categoryPaths: remainingPath,
  }

  addCategoryToBreadcrumbs(parent, routeLocale, crumbs)
  addParentCategoryToBreadcrumbs({ activeCategory: parent, crumbs, routeLocale })
}

const createAdIdLabel = (adId: number, routeLocale: RouteLocale) =>
  routeLocale === ROUTE_LOCALE.fr ? `Code d'annonce ${adId}` : `Ad ID ${adId}`

const createKeywordLabel = (keywords: string, routeLocale: RouteLocale) =>
  routeLocale === ROUTE_LOCALE.fr ? `Résultats pour "${keywords}"` : `Results for "${keywords}"`

/**
 * Add title of the current page (with keyword) to the breadcrumb
 *
 * @param keywords
 * @param crumbs
 * @param languageKey
 */

const handleNoParentCategory = (
  crumbs: BreadcrumbsProps['crumbs'],
  routeLocale: RouteLocale,
  keywords?: string | null,
  adId?: number
) => {
  if (keywords) {
    const keywordLabel = createKeywordLabel(keywords, routeLocale)
    crumbs.push({ label: keywordLabel })
  }

  if (adId) {
    const adIdLabel = createAdIdLabel(adId, routeLocale)
    crumbs.push({ label: adIdLabel })
  }
}

/**
 * Create the Home breadcrumb
 *
 * @param routeLocale
 * @returns
 */
const createHomeCrumb = (routeLocale: RouteLocale): BreadcrumbsProps['crumbs'] => {
  const homeLabel = routeLocale === ROUTE_LOCALE.fr ? 'Accueil' : 'Home'
  return [{ label: homeLabel, href: '/' }]
}

/**
 * Get the Home breadcrumb
 *
 * @param crumbs
 * @param routeLocale
 * @returns
 */
const getHomeCrumb = (routeLocale: RouteLocale) => {
  return createHomeCrumb(routeLocale)
}

/**
 * The SRP breadcrumbs should always return the category path for the search.
 * Always starting with "Home" page link, then L1 > L2 > L3 > Results for <Keyword>
 *
 * This centralized function should work on SSR and on Client-side render.
 * Translation has been done manually in this function for SSR to work.
 */
export const getBreadcrumbs = ({
  activeCategory,
  adId,
  crumbs = [],
  keywords,
  routeLocale,
  locationName,
}: GetSrpBreadcrumbProps): BreadcrumbsProps['crumbs'] => {
  if (!activeCategory) return getHomeCrumb(routeLocale)

  /** The first crumb should always be "Home" */
  crumbs = getHomeCrumb(routeLocale)

  // Add active category to breadcrumbs
  if (activeCategory.id !== 0) {
    addCategoryToBreadcrumbs(activeCategory, routeLocale, crumbs, locationName)
  }

  // Add parent list to breadcrumbs
  addParentCategoryToBreadcrumbs({
    activeCategory,
    crumbs,
    routeLocale,
  })

  handleNoParentCategory(crumbs, routeLocale, keywords, adId)

  return crumbs
}
