import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgVacationType: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.006 12.15c-.337 0-.63-.123-.88-.37a1.186 1.186 0 01-.376-.874c0-.338.123-.631.37-.881.245-.25.537-.375.874-.375.338 0 .631.123.881.37.25.245.375.537.375.874 0 .337-.123.631-.369.881s-.538.375-.875.375zm.944 8.55h9.75v-5.9h-2.15c-1.017 0-1.879-.342-2.587-1.027C9.254 13.09 8.9 12.281 8.9 11.35c0-.75.221-1.467.663-2.15.441-.683 1.07-1.2 1.887-1.55.084-1.167.534-2.075 1.35-2.725.817-.65 1.671-.975 2.563-.975.891 0 1.75.325 2.575.975.825.65 1.279 1.558 1.362 2.725.8.35 1.417.867 1.85 1.55.434.683.65 1.4.65 2.15 0 .931-.354 1.739-1.062 2.423-.709.685-1.571 1.027-2.588 1.027h-2.1v5.9h4.425c.183 0 .34.067.474.201.134.134.201.292.201.475s-.067.34-.2.474a.65.65 0 01-.475.2H4.125a1.04 1.04 0 01-.759-.313 1.02 1.02 0 01-.316-.752V17.65c-.316.183-.537.146-.662-.112A1.696 1.696 0 012.2 16.8v-3a.63.63 0 01.187-.463.629.629 0 01.463-.187h2.3c.184 0 .339.062.463.187a.63.63 0 01.187.463v3c0 .233-.062.48-.187.738s-.346.295-.663.112v3.05zm7.6-7.25h5.6a2.36 2.36 0 001.613-.607c.458-.404.687-.902.687-1.493 0-.605-.204-1.135-.612-1.591a3.69 3.69 0 00-1.488-1.01l-.35-.1V8.3c-.033-.867-.291-1.583-.775-2.15-.483-.567-1.108-.85-1.875-.85-.766 0-1.391.283-1.875.85-.483.567-.742 1.283-.775 2.15v.35l-.35.1a3.69 3.69 0 00-1.487 1.009 2.312 2.312 0 00-.613 1.591c0 .591.23 1.089.688 1.493a2.36 2.36 0 001.612.607z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgVacationType
