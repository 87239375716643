import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMapPinLocation: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 11.2871H17.7098C18.1683 11.2871 18.568 11.5989 18.6797 12.0436L20.6879 20.0436C20.8464 20.675 20.3689 21.2871 19.718 21.2871H4.19927C3.57501 21.2871 3.10335 20.7215 3.21555 20.1074L4.67724 12.1074C4.76404 11.6323 5.17799 11.2871 5.66095 11.2871H7.5"
        stroke="#3E4153"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0051 2.66992C9.02019 2.66992 6.6001 5.12022 6.6001 8.14424C6.6001 11.6454 10.455 13.7126 11.4179 14.992C11.9595 15.7119 11.9736 15.7261 12.5798 14.9979C13.7061 13.6438 17.4001 11.6454 17.4001 8.14424C17.4001 5.12022 14.98 2.66992 11.9951 2.66992"
        fill="white"
      />
      <path
        d="M11.4179 14.992L11.8174 14.6914L11.8174 14.6913L11.4179 14.992ZM12.5798 14.9979L12.9641 15.3178L12.9642 15.3176L12.5798 14.9979ZM12.0051 2.16992C8.7381 2.16992 6.1001 4.85006 6.1001 8.14424H7.1001C7.1001 5.39039 9.30227 3.16992 12.0051 3.16992V2.16992ZM6.1001 8.14424C6.1001 10.0901 7.17336 11.6026 8.28951 12.7499C8.84974 13.3258 9.43957 13.8284 9.93235 14.2533C10.4442 14.6947 10.8172 15.0254 11.0184 15.2927L11.8174 14.6913C11.5371 14.3189 11.0672 13.9115 10.5854 13.496C10.0846 13.0641 9.53016 12.5911 9.0063 12.0526C7.95428 10.9712 7.1001 9.69949 7.1001 8.14424H6.1001ZM11.0183 15.2926C11.1461 15.4624 11.2688 15.6266 11.3808 15.7441C11.4817 15.8499 11.677 16.033 11.966 16.0379C12.2509 16.0428 12.454 15.871 12.5621 15.7688C12.6836 15.654 12.8189 15.4922 12.9641 15.3178L12.1955 14.678C12.0376 14.8677 11.9439 14.9772 11.8752 15.042C11.8429 15.0726 11.835 15.0747 11.8468 15.0682C11.8628 15.0593 11.9117 15.0368 11.9832 15.0381C12.0554 15.0393 12.1054 15.0639 12.1245 15.0756C12.1387 15.0843 12.1332 15.0841 12.1045 15.054C12.0426 14.989 11.9604 14.8815 11.8174 14.6914L11.0183 15.2926ZM12.9642 15.3176C13.2125 15.0191 13.6159 14.6713 14.1335 14.2277C14.636 13.7971 15.2213 13.296 15.7727 12.7228C16.8731 11.5787 17.9001 10.0847 17.9001 8.14424H16.9001C16.9001 9.70493 16.0801 10.9607 15.052 12.0296C14.539 12.5629 13.9897 13.0341 13.4828 13.4684C12.991 13.8898 12.5103 14.2996 12.1954 14.6781L12.9642 15.3176ZM17.9001 8.14424C17.9001 4.85006 15.2621 2.16992 11.9951 2.16992V3.16992C14.6979 3.16992 16.9001 5.39039 16.9001 8.14424H17.9001Z"
        fill="#3E4153"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1601 8.0314C14.1601 9.21526 13.1938 10.1761 12.0001 10.1761C10.8063 10.1761 9.84009 9.21526 9.84009 8.0314C9.84009 6.84611 10.8063 5.88672 12.0001 5.88672C13.1938 5.88672 14.1601 6.84611 14.1601 8.0314Z"
        fill="white"
      />
      <path
        d="M14.1601 8.0314C14.1601 9.21526 13.1938 10.1761 12.0001 10.1761C10.8063 10.1761 9.84009 9.21526 9.84009 8.0314C9.84009 6.84611 10.8063 5.88672 12.0001 5.88672C13.1938 5.88672 14.1601 6.84611 14.1601 8.0314"
        stroke="#3E4153"
      />
    </Icon>
  )
}
export default SvgMapPinLocation
