import { type FC } from 'react'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { PostAdCTA } from '@/components/shared/global-header/PostAdCTA'
import { SearchLocation } from '@/components/shared/global-header/search-bar/SearchLocation'
import { LoggedInLinks } from '@/components/shared/global-header/tools/logged-in-links'
import { LoggedOutLinks } from '@/components/shared/global-header/tools/logged-out-links'
import { SessionMenu } from '@/components/shared/global-header/tools/session-menu'
import { ToolsContainer } from '@/components/shared/global-header/tools/styled'
import { useSession } from '@/features/auth/hooks/useSession'
import { RemoteParamKeys, useExperiment } from '@/lib/firebase/hooks'
import { ExperimentValues } from '@/lib/firebase/types'

type ToolsProps = {
  showPostAdCTA: boolean
}

export const Tools: FC<ToolsProps> = ({ showPostAdCTA }) => {
  const { optimisticIsAuthenticated } = useSession()

  const { enabled, group } = useExperiment(RemoteParamKeys.HEADER_SIMPLIFIED_TEST) ?? {}
  const isHeaderSimplified = enabled === true && group === ExperimentValues.GROUP_B

  return (
    <ToolsContainer data-testid="global-header-tools" isHeaderSimplified={isHeaderSimplified}>
      <ErrorBoundary fingerprintId="Tools">
        {isHeaderSimplified ? <SearchLocation id="set-search-range-header" /> : null}
        {optimisticIsAuthenticated ? (
          <>
            <LoggedInLinks />
            <SessionMenu />
          </>
        ) : (
          <LoggedOutLinks />
        )}
      </ErrorBoundary>

      {showPostAdCTA && <PostAdCTA />}
    </ToolsContainer>
  )
}
