import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { TextLink } from '@/ui/atoms/text-link'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SrpSortContainer = styled.div(
  ({ theme }) => `
  height: 4.8rem;
  margin-top: ${theme.spacing.default};
  position: relative;
  z-index: ${zIndexRegistry.SRP_SORT_DROPDOWN};

  & button {
    color: ${theme.colors.grey.primary};
  }

  ${MEDIA_QUERIES(theme).large} {
    margin-top: 0;
  }
`
)

export const MobileSortTextLinkButton = styled(TextLink).attrs(() => ({ as: 'button' }))(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing.mini};
  `
)
