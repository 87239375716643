import {
  type DocumentNode,
  type StoreObject,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client'
import {
  type RequestViewingState,
  type SearchResultsByUrlInput,
  type SearchResultsPageState,
  GetAuthModalStateDocument,
  GetFilterDrawerStateDocument,
  GetHomeFeedAdsDocument,
  GetListingsDrawerStateDocument,
  GetMapProviderDocument,
  GetSearchCategoryDocument,
  GetSearchKeywordDocument,
  GetSearchLoadingStateDocument,
} from '@kijiji/generated/graphql-types'

import { DEFAULT_CATEGORY } from '@/constants/category'
import { getSrpUrlCacheKey } from '@/domain/srp/getSrpUrlCacheKey'
import { type RequestHeaders } from '@/lib/apollo/apolloClient'
import { getInitialCookieCacheData } from '@/lib/apollo/cacheFromCookies'
import { currentSrpUrl } from '@/lib/apollo/currentSrpUrl'

// TODO: Think about organizing this into its own file to handle more upcoming cache changes
export const getDataIdFromObject = (object: Readonly<StoreObject>) => {
  // Customize cache key for MenuItem objects to include Location Id because its part of the seoUrl
  if (object.__typename === 'MenuItem') {
    const match = object.seoUrl?.toString().match(/l(\d+)$/)
    const locationId = (match?.length && match[1].toString()) ?? ''
    // Possibly attach locale here? Or when user flip locale, we need to clear cache and refire all queries.
    return `MenuItem:${object.id}${locationId ? `:${locationId}` : ''}`
  }
  // Use default behavior for other types
  return defaultDataIdFromObject(object)
}

export const getCacheFields = () => {
  const cache = {
    fields: {
      searchResultsPageByUrl: {
        keyArgs: (args: Record<'input', SearchResultsByUrlInput> | null) => {
          return (args && getSrpUrlCacheKey(args.input.url)) || undefined
        },
      },
      /**
       * The HomepageGallery & HomeFeed keyArgs are set to support the "fetchMore" pagination
       * The cache will only be wiped when those "keyArgs" change
       * */
      listingHomepageGallery: { keyArgs: ['locationId'] },
      homeFeed: { keyArgs: ['locationId', 'cid', 'userId', 'location'] },
      menuPrefetch: {
        keyArgs: ['locationId', 'categoryId'],
      },
      srp: {
        merge(
          existing: SearchResultsPageState,
          incoming: SearchResultsPageState,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { mergeObjects }: { mergeObjects: any }
        ) {
          // TODO: Do i need this? what was i doing?
          return mergeObjects(existing, incoming)
        },
      },
      requestViewingState: {
        /*
         * Merges two RequestViewingState objects together using Apollo's merge strategy.
         * This is used to handle updates to the cache when new data arrives,
         * ensuring that existing and incoming states are properly combined.
         */
        merge(
          existing: RequestViewingState,
          incoming: RequestViewingState,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { mergeObjects }: { mergeObjects: any }
        ) {
          return mergeObjects(existing, incoming)
        },
      },
    },
    currentSrpUrl: {
      read() {
        return currentSrpUrl()
      },
    },
  }

  return cache
}

export const localInitialState: Array<{
  query: DocumentNode
  data: Record<string, unknown>
}> = [
  {
    query: GetSearchKeywordDocument,
    data: { searchKeyword: { keyword: null } },
  },
  {
    query: GetSearchCategoryDocument,
    data: { searchCategory: DEFAULT_CATEGORY },
  },
  {
    query: GetListingsDrawerStateDocument,
    data: {
      srp: {
        isListingsDrawerOpen: true,
      },
    },
  },
  {
    query: GetFilterDrawerStateDocument,
    data: {
      srp: {
        isFilterDrawerOpen: false,
        selectedAccordionItems: [],
      },
    },
  },
  {
    query: GetSearchLoadingStateDocument,
    data: {
      srp: {
        loadingFilters: true,
        loadingResults: true,
      },
    },
  },
  {
    query: GetMapProviderDocument,
    data: {
      mapProvider: {
        isLoading: true,
        error: false,
        apiKey: '',
      },
    },
  },
  {
    query: GetAuthModalStateDocument,
    data: {
      authModalState: {
        isModalOpen: false,
        modalVariant: null,
        callbackUrl: null,
      },
    },
  },
  {
    query: GetHomeFeedAdsDocument,
    data: {
      homeFeedAds: {
        adSlots: 0,
      },
    },
  },
]

export const writeInitialData = (cache: InMemoryCache, cookies?: RequestHeaders['cookies']) => {
  const initialCookieData = getInitialCookieCacheData(cookies)
  const initialStates = [...localInitialState, ...initialCookieData]

  initialStates.forEach((query) => cache.writeQuery(query))
}

export const initializeCache = (): InMemoryCache => {
  const cache = new InMemoryCache({
    dataIdFromObject: getDataIdFromObject,
    typePolicies: {
      Query: {
        fields: getCacheFields().fields,
      },
      ListingLocation: {
        keyFields: false,
      },
    },
  })

  return cache
}
