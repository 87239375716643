import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import qs from 'query-string'
import { useEffect } from 'react'

import { useFavouriteListingUpdate } from '@/hooks/useFavouriteListingUpdate'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

/**
 * This hook should be used whenever the page has one or more favourite buttons
 * It should be per page, not per button
 *
 * It will trigger a fn to update the user's favourite list after they have signed in
 * based on the query parameters
 */
export const useFavouriteOnLoginCallback = () => {
  const { asPath, query, replace } = useRouter()

  const { data: userData } = useSession()
  const { updateFavourite } = useFavouriteListingUpdate()

  /**
   * UseEffect to be triggered if the user has signed in to favourite a listing
   * The decision is made based on a query parameter
   * Once the function has been triggered the query parameter should be removed from the URL
   */
  useEffect(() => {
    const listingId = query.listingId
    if (typeof listingId !== 'string' || !userData?.user) return
    /**
     * Remove listingId from the query params
     * If the initial value of listingId is not overwritten, the request will happen multiple times
     */
    delete query.listingId
    const { url } = qs.parseUrl(asPath)
    replace(qs.stringifyUrl({ url, query }))

    updateFavourite(false, {
      variables: { listingId },
      onCompleted: () => {
        trackEvent({
          action: GA_EVENT.WatchlistAdd,
          label: `adId=${listingId}`,
        })
      },
      onError: (error) => {
        // catch this error legitimate error
        if (error.message.includes('already favourited')) {
          return
        }
      },
    })
  }, [userData, asPath, updateFavourite, query, replace])
}
