import { type SearchCategoryPath, type UserLocation } from '@kijiji/generated/graphql-types'

import { adSenseConfiguration } from '@/features/advertisement/components/adsense/adsenseConfig'
import { generateAdSenseChannel } from '@/features/advertisement/utils/adsense/generateAdSenseChannel'

type GetAdSenseConfigParams = {
  allCategoryIds: string[]
  location?: UserLocation
  currentPage: number
  keywords: string
  routeLocale: string
  categoryPath: SearchCategoryPath[]
  experimentName?: string | null
  experimentGroup?: string
}

export const getAdSenseConfig = ({
  allCategoryIds,
  location,
  currentPage,
  keywords,
  routeLocale,
  categoryPath,
  experimentName,
  experimentGroup,
}: GetAdSenseConfigParams) => {
  // We concatenate two channels, with the second one having `kcan19539_a` passed as the "test experiment group"
  // Ex: (e.g. 0_w_brl_10_o_x_1_o_x+1_w_brl_10_o_x_1_kcan999_a)
  // See google's documentation: https://developers.google.com/custom-search-ads/web/reference

  const adSenseChannels = [
    generateAdSenseChannel({
      allCategoryIds,
      locationId: location?.id,
      currentPage,
      keywords,
      overrides: {
        expInd: '0',
      },
    }),
    experimentName && experimentGroup
      ? generateAdSenseChannel({
          allCategoryIds,
          locationId: location?.id,
          currentPage,
          keywords,
          overrides: {
            expInd: '1',
            expName: experimentName,
            expGroup: experimentGroup,
          },
        })
      : undefined,
  ]
    .filter(Boolean)
    .join('+')

  return adSenseConfiguration({
    active: true,
    containers: [
      {
        container: 'AFSTop',
        width: '100%',
        number: 2,
      },
      {
        container: 'AFSBottom',
        width: '100%',
        number: 2,
      },
    ],
    hl: routeLocale,
    keywords,
    location,
    currentPage,
    categoryPath,
    channel: adSenseChannels,
  })
}
