import { RemoteConfigs } from '@/lib/firebase/config/configs'
import { RemoteEnvs } from '@/lib/firebase/config/envs'
import { RemoteExperiments } from '@/lib/firebase/config/experiments'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import { type ExtractKeys, type TrackingKey } from '@/lib/firebase/types'

export const DefinedFirebaseConfig = {
  ...RemoteConfigs,
  ...RemoteEnvs,
  ...RemoteExperiments,
  ...RemoteToggles,
}

export const RemoteParamKeys: ExtractKeys<typeof DefinedFirebaseConfig> = Object.fromEntries(
  Object.entries(DefinedFirebaseConfig).map(([k, v]) => [k, v.key])
) as ExtractKeys<typeof DefinedFirebaseConfig>

const TrackedConfig = Object.values({ ...RemoteToggles, ...RemoteExperiments })

export const TRACKING_KEYS = TrackedConfig.reduce(
  (acc, config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Ignore this error about missing tracking keys.
    if (config.trackingKey) {
      return {
        ...acc,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Ignore this error about missing tracking keys.
        [config.key]: config.trackingKey,
      }
    }

    return acc
  },
  {} as { [key: string]: TrackingKey }
)
