import { isCarsTrucks } from '@kijiji/category'
import { DrawerDraggable, SelectVariant } from '@kijiji/ui'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { SrpHeaderSearchTitle } from '@/components/srp/header/styled'
import { SrpPagination } from '@/components/srp/pagination'
import { PopularRelatedKeywords } from '@/components/srp/popular-related-keywords/PopularRelatedKeywords'
import { SrpResults } from '@/components/srp/results-sort/results'
import { SrpSort } from '@/components/srp/results-sort/sort'
import { SearchFloatingButtonsMapSrp } from '@/components/srp/search-floating-buttons'
import { RenderListingCard } from '@/components/srp/search-list/RenderListingCard'
import { RenderTopAds } from '@/components/srp/search-list/RenderTopAds'
import {
  type SearchListContainerProps,
  SearchListContainer,
} from '@/components/srp/search-list/styled'
import { SrpLoading } from '@/components/srp/srp-loading'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import {
  getExtendedRadius,
  hasExtendedRadiusListings,
  isLowInventorySearch,
  isZeroInventorySearch,
} from '@/domain/lowInventory'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { AdSense } from '@/features/advertisement/components/adsense/AdSense'
import { ImageSeoJsonLdWrapper } from '@/features/seo/components/ImageSeoJsonLdWrapper'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useListingsDrawerState } from '@/hooks/srp/useListingsDrawerState'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { type Listing } from '@/types/search'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

const ZeroResultsActionBox = dynamic(
  () => import('@/components/srp/zero-results/').then((mod) => mod.ZeroResultsActionBox),
  { ssr: true } // to prevent layout shift
)

const MiniLeaderboardAdSlot = dynamic(
  () =>
    import('@/components-page/srp/advertisement/MiniLeaderboardAdSlot').then(
      (mod) => mod.MiniLeaderboardAdSlot
    ),
  { ssr: false }
)

const PaginationLinkContainer = dynamic(
  () => import('./styled').then((mod) => mod.PaginationLinkContainer),
  { ssr: false }
)

export type SearchListProps = {
  extendedRadiusListings: Listing[]
  totalPageCount: number
  isMobile?: boolean
  searchTitle?: string
  shouldRenderMapSRP?: boolean
  isMapSRPEnabled?: boolean
  setShouldRenderMapSRP?: React.Dispatch<React.SetStateAction<boolean>>
}

const autosSearchListContainerGap: SearchListContainerProps['gap'] = {
  small: 'defaultSmall',
  xLarge: 'default',
}

const PARTIALLY_OPENED = 0.15
const FULLY_OPENED = 1

const drawerSnapPoints = [PARTIALLY_OPENED, FULLY_OPENED]

export const SearchList = ({
  extendedRadiusListings = [],
  searchTitle,
  totalPageCount,
  isMobile,
  shouldRenderMapSRP,
}: SearchListProps) => {
  const { colors, spacing } = useTheme()

  const [activeSnapPoint, setActiveSnapPoint] = useState<string | number | null>(PARTIALLY_OPENED)

  const { t } = useTranslation(['srp', 'common'])

  const { data, loadingResults: loading } = useGetSearchResultsData()

  const { pagination, searchQuery, results } = data || {}
  const { topListings: topAds = [], mainListings: listings = [] } = results || {}

  const globalKeyword = searchQuery?.keywords || ''
  const { totalCount: totalListings = 0, offset = 0, limit = SRP_LISTING_LIMIT } = pagination || {}
  const { apiLocale } = useLocale()

  const currentPage = getCurrentPageFromPagination({ offset, limit })

  const location = getUserLocationFromSearchQuery(searchQuery)
  const category = getSearchCategoryFromSearchQuery(searchQuery?.category, apiLocale)

  const extendedRadius = useMemo(
    () => getExtendedRadius(location?.area?.radius),
    [location?.area?.radius]
  )
  const isZeroSrp = useMemo(() => isZeroInventorySearch(totalListings), [totalListings])
  const isLowSrp = useMemo(() => isLowInventorySearch(totalListings), [totalListings])
  const { isListingsDrawerOpen } = useListingsDrawerState()

  // We want to check the category the user is searching in, not the category
  // of the listing.
  const isCarsTrucksSearch = useMemo(() => isCarsTrucks(category.id), [category.id])
  const listingsGap = isCarsTrucksSearch ? autosSearchListContainerGap : undefined

  const searchPageType = useMemo(() => getSearchPageType(globalKeyword), [globalKeyword])

  useEffect(() => {
    if (isLowSrp) {
      trackEvent({ action: searchPageType, label: 'SuggestionBoxLoad' })
    }
  }, [isLowSrp, searchPageType])

  const listingsContent = () => (
    <Flex flexDirection="column" gap={spacing.default}>
      <>
        {loading ? (
          <SrpLoading variant="listing-card" />
        ) : (
          <>
            <ImageSeoJsonLdWrapper
              listings={listings.concat(topAds)}
              id="srp-listing-card-image-json-ld"
            />

            {/* Provincial Top Ads (PTAs) need to display on ZSRPs */}
            <RenderTopAds
              globalCategoryId={category.id}
              topAds={topAds}
              isMobile={isMobile}
              listingsGap={listingsGap}
              shouldRenderMapSRP={shouldRenderMapSRP}
            />

            {!shouldRenderMapSRP && (
              <PopularRelatedKeywords
                categoryId={category.id.toString()}
                keywords={searchQuery?.keywords}
                location={{ id: location.id }}
                locationArea={location.area}
              />
            )}

            {isZeroSrp && <ZeroResultsActionBox />}

            {!!listings.length && (
              <SearchListContainer data-testid="srp-search-list" gap={listingsGap}>
                {listings.map((item, index) => (
                  <RenderListingCard
                    key={item.id}
                    item={item}
                    index={index}
                    isMobile={isMobile}
                    isMapCard={shouldRenderMapSRP}
                  />
                ))}
              </SearchListContainer>
            )}

            {isLowSrp && (
              <>
                {hasExtendedRadiusListings(extendedRadiusListings.length, extendedRadius) && (
                  <>
                    <Spacing mTop="2rem" mBottom="1rem">
                      <HeadlineText
                        color={colors.grey.primary}
                        size="xLarge"
                        weight="medium"
                        as="h2"
                      >
                        {t('srp:extended_listings.title', { extendedRadius })}
                      </HeadlineText>
                    </Spacing>

                    <SearchListContainer data-testid="srp-extended-list" gap={listingsGap}>
                      {extendedRadiusListings.map((item, index) => (
                        <RenderListingCard
                          key={item.id}
                          item={item}
                          index={index}
                          totalListings={extendedRadiusListings.length}
                          isMobile={isMobile}
                          isMapCard={shouldRenderMapSRP}
                        />
                      ))}
                    </SearchListContainer>
                  </>
                )}

                <Spacing mTop="6rem">
                  <ZeroResultsActionBox />
                </Spacing>
              </>
            )}
          </>
        )}
      </>
      {/* Minileaderboard should not appear on zSRP */}
      <AdSense id="AFSBottom">{!!totalListings && <MiniLeaderboardAdSlot />}</AdSense>
      <PaginationLinkContainer justifyContent="center" alignItems="center">
        {totalPageCount > 1 && (
          <SrpPagination selectedIndex={currentPage} totalPageCount={totalPageCount} />
        )}
      </PaginationLinkContainer>
    </Flex>
  )

  return shouldRenderMapSRP && isMobile ? (
    <DrawerDraggable
      snapPoints={drawerSnapPoints}
      hasBackdrop
      activeSnapPoint={activeSnapPoint}
      setActiveSnapPoint={(snapPoint) => setActiveSnapPoint(snapPoint)}
      isOpen={isListingsDrawerOpen}
      modal={false}
      dismissible={false}
      onCancel={() => {
        setActiveSnapPoint(PARTIALLY_OPENED)
      }}
    >
      <SearchFloatingButtonsMapSrp isPartiallyOpen={activeSnapPoint === PARTIALLY_OPENED} />
      <Flex
        alignItems="center"
        flexDirection="column"
        style={{ marginTop: 22, marginBottom: 8, gap: 10 }}
      >
        <SrpHeaderSearchTitle
          aria-live="polite"
          color={colors.grey.primary}
          data-testid="srp-header-title"
          size="medium"
          style={{ textAlign: 'center', alignSelf: 'center' }}
        >
          {searchTitle}
        </SrpHeaderSearchTitle>
        <SrpResults />
      </Flex>
      <SrpSort variant={SelectVariant.ROUNDED} isMobile={isMobile} />
      {listingsContent()}
    </DrawerDraggable>
  ) : (
    <>{listingsContent()}</>
  )
}
