import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type SrpGridProps = {
  shouldRenderMapSRP: boolean
}

export const SrpGrid = styled.div<SrpGridProps>(
  ({ theme, shouldRenderMapSRP }) => `
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    width: 100%;
    ${!shouldRenderMapSRP ? 'max-width: 1440px;' : ''}
    ${!shouldRenderMapSRP ? 'margin: 0 auto;' : ''} // Center when constrained

    ${MEDIA_QUERIES(theme).large} {
      grid-template-columns: ${shouldRenderMapSRP ? '70% 30%' : '20% 80%'};
      gap: 0 ${theme.spacing.default};

      > * {
        min-width: 0;
        width: 100%;
      }
    }
  `
)
