import { type LanguageKey } from '@/domain/locale'
import {
  adSizesMap,
  GPT_COMPONENT_ID,
  GPT_GLOBAL_PROPERTIES,
  SIZE_MAPPING_NAMES,
} from '@/features/advertisement/constants/adSlots'
import { PREBID_SLOT_CONFIGS } from '@/features/advertisement/constants/prebid'
import { type AdConfig, type GptTargeting } from '@/features/advertisement/types/adConfig'
import {
  createMediaTypes,
  flattenGptArray,
  generateAdUnitPath,
  shouldDisableLazyLoad,
} from '@/features/advertisement/utils/globalOptimizationTool'

type Device = 'desktop' | 'mweb'

export const DESKTOP_SLOTS_SRP = [
  GPT_COMPONENT_ID.TOP_LEADERBOARD,
  GPT_COMPONENT_ID.INLINE_1,
  GPT_COMPONENT_ID.INLINE_2,
  GPT_COMPONENT_ID.INLINE_3,
  GPT_COMPONENT_ID.INLINE_4,
  GPT_COMPONENT_ID.MINI_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.SKYSCRAPER,
]

export const DESKTOP_SLOTS_ZSRP = [
  GPT_COMPONENT_ID.TOP_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.SKYSCRAPER,
]

export const MOBILE_SLOTS_SRP = [
  GPT_COMPONENT_ID.INLINE_1,
  GPT_COMPONENT_ID.INLINE_2,
  GPT_COMPONENT_ID.INLINE_3,
  GPT_COMPONENT_ID.INLINE_4,
  GPT_COMPONENT_ID.MINI_LEADERBOARD,
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.MWEB_LEADERBOARD,
]

export const MOBILE_SLOTS_ZSRP = [
  GPT_COMPONENT_ID.BASE_LEADERBOARD,
  GPT_COMPONENT_ID.MWEB_LEADERBOARD,
]

const adSlotConfig = {
  mweb: {
    zsrp: MOBILE_SLOTS_ZSRP,
    srp: MOBILE_SLOTS_SRP,
  },
  desktop: {
    zsrp: DESKTOP_SLOTS_ZSRP,
    srp: DESKTOP_SLOTS_SRP,
  },
}

export const SLOT_TARGETING_VALUES = {
  [GPT_COMPONENT_ID.TOP_LEADERBOARD]: { pos: 'top_header' },
  [GPT_COMPONENT_ID.INLINE_1]: { pos: 'bottom' },
  [GPT_COMPONENT_ID.INLINE_2]: { pos: 'bottom2' },
  [GPT_COMPONENT_ID.INLINE_3]: { pos: 'bottom3' },
  [GPT_COMPONENT_ID.INLINE_4]: { pos: 'bottom4' },
  [GPT_COMPONENT_ID.MINI_LEADERBOARD]: {}, // no values for mini leaderboard
  [GPT_COMPONENT_ID.BASE_LEADERBOARD]: { pos: 'base' },
  [GPT_COMPONENT_ID.SKYSCRAPER]: { pos: 'top' },
  [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: { pos: 'top' },
}

const generateAdSlots = (
  slotIds: string[],
  device: Device,
  locale: LanguageKey,
  isMastheadReduced: boolean | undefined,
  isDesktopMapView: boolean
) => {
  return slotIds.map((slotId: string) => {
    const prebidSlotConfigs = PREBID_SLOT_CONFIGS[slotId]?.[device]?.[locale]

    // inline ids are appended with a number, but the ad sizes & sizeMappings are the same
    const isInlineAd = slotId.includes('inline')
    let sizeMappingName = SIZE_MAPPING_NAMES[slotId]
    let adSizes = adSizesMap[slotId]

    if (isInlineAd) {
      // inline ads have different size mappings for desktop map SRP view
      const INLINE_MAPPING_NAME = isDesktopMapView
        ? GPT_COMPONENT_ID.INLINE_MAP_SRP
        : GPT_COMPONENT_ID.INLINE

      sizeMappingName = SIZE_MAPPING_NAMES[INLINE_MAPPING_NAME]
      adSizes = adSizesMap[INLINE_MAPPING_NAME]
    } else if (slotId === GPT_COMPONENT_ID.TOP_LEADERBOARD && isMastheadReduced) {
      // these size overrides are for the a/b test where the masthead banner is reduced
      sizeMappingName = SIZE_MAPPING_NAMES[GPT_COMPONENT_ID.TOP_LEADERBOARD_REDUCED]
      adSizes = adSizesMap[GPT_COMPONENT_ID.TOP_LEADERBOARD_REDUCED]
    }

    return {
      id: slotId,
      metaData: {},
      ...(shouldDisableLazyLoad(slotId) ? { enableLazyLoad: false } : {}),
      prebid: [{ bids: prebidSlotConfigs, mediaTypes: createMediaTypes(adSizes) }],
      sizeMappingName,
      sizes: adSizes,
      targeting: SLOT_TARGETING_VALUES[slotId],
    }
  })
}

export const createSrpGptConfig = ({
  gptTargeting,
  isMastheadReduced,
  isDesktopMapView = false,
}: {
  gptTargeting: GptTargeting[]
  isMastheadReduced?: boolean
  isDesktopMapView?: boolean
}) => {
  const flattenedGptArray = flattenGptArray(gptTargeting)
  const { lang, dv, pagetype } = flattenedGptArray

  const device = dv === 'm' ? 'mweb' : 'desktop'
  const deviceConfig = adSlotConfig[device]

  const simplifiedPageType = pagetype === 'zsrp' ? 'zsrp' : 'srp'
  const slotIds = deviceConfig[simplifiedPageType] || deviceConfig.srp

  const locale = lang === 'fr' ? lang : 'en'

  const srpGptConfig: AdConfig = {
    ...GPT_GLOBAL_PROPERTIES(isDesktopMapView),
    path: generateAdUnitPath(flattenedGptArray),
    targeting: flattenedGptArray,
    slots: generateAdSlots(slotIds, device, locale, isMastheadReduced, isDesktopMapView),
  }

  return srpGptConfig
}
