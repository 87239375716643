import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import {
  AD_SLOTS,
  LEADERBOARD_SCROLL_AWAY_OFFSET,
} from '@/features/advertisement/constants/adSlots'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

type TopLeaderboardAdContainerProps = {
  isSticky: boolean
  shouldScrollAway: boolean
  stickyOffset: string
  isMastheadReduced: boolean
}
/**
 * The padding prevents layout shift if the shorter ad (90px) is loaded.
 * The padding is removed if the taller ad (250px) is loaded.
 * When the ads becomes sticky:
 *  - a white full-width background is added to the container
 *  - the bottom padding is removed is removed from the shorter ad
 */
export const TopLeaderboardAdContainer = styled.div<TopLeaderboardAdContainerProps>(
  ({ theme, isSticky, shouldScrollAway, isMastheadReduced }) => {
    const isStickyAndExpOff = isSticky && !isMastheadReduced

    return `
    overflow-x: hidden;
    ${MEDIA_QUERIES(theme).medium}  {
      display: flex;
      min-height: ${AD_SLOTS.LEADERBOARD.HEIGHT};
      z-index: ${zIndexRegistry.TOP_LEADERBOARD};
    }
    ${MEDIA_QUERIES(theme).large}  {
      background: ${isStickyAndExpOff ? 'white' : 'transparent'};
      box-shadow: ${isStickyAndExpOff ? '0 0.2rem 0.5rem rgb(0 0 0 / 5%)' : 'none'};
      display: flex;
      min-height: ${isMastheadReduced ? AD_SLOTS.LEADERBOARD.HEIGHT : AD_SLOTS.LEADERBOARD_XL.HEIGHT};  
      position: ${!isMastheadReduced && 'sticky'};
      top: ${isStickyAndExpOff ? LEADERBOARD_SCROLL_AWAY_OFFSET : 'auto'};
      transform: translateY(${shouldScrollAway && isStickyAndExpOff ? '-50vh' : '0'});
      transition: ${isStickyAndExpOff && 'transform 0.8s ease-out'};
      align-items: center;
    }
  `
  }
)

export const StyledTopSlotWrapper = styled.div`
  margin: 0 auto;
`

export const InlineAdSlotWrapper = styled.li`
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
`

export const StyledInlineAdSlot = styled(AdvertisingSlot)`
  max-width: 100%;

  & iframe {
    max-width: 100%;
    overflow: hidden;
  }
`

export const StyledBaseLeaderboard = styled(AdvertisingSlot)(({ theme }) => {
  return `
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: ${AD_SLOTS.BASE_LEADERBOARD_S.WIDTH};
  max-height: ${AD_SLOTS.BASE_LEADERBOARD_S.HEIGHT};

  ${MEDIA_QUERIES(theme).medium}  {
    max-width: ${AD_SLOTS.LEADERBOARD.WIDTH};
    max-height: ${AD_SLOTS.LEADERBOARD.HEIGHT};
  }

  ${MEDIA_QUERIES(theme).large}  {
    max-width: ${AD_SLOTS.LEADERBOARD_XL.WIDTH};
    max-height: ${AD_SLOTS.LEADERBOARD_XL.HEIGHT};
  }
`
})

/**
 * This slot is for a custom ad unit that will be similar in styling & size to an organic listing.
 * The unit container is a fixed size.
 * AdOps haven't finalied this size yet, but we're currently testing 510x210px.
 * Added a new size 956, 208. so adding a new max-height for the case when adsense come along other ads
 * */
export const StyledMiniLeaderboard = styled(AdvertisingSlot)(({ theme }) => {
  return `
    margin: ${theme.spacing.default} auto 0;
    display: flex;
    overflow: hidden;

    width: 100%;

    ${MEDIA_QUERIES(theme).small}  {
      max-height: 9rem;
    }

    ${MEDIA_QUERIES(theme).medium}  {
      max-height: 15rem;
    }

    ${MEDIA_QUERIES(theme).large}  {
      max-height: 21rem;
    }

    &:empty {
      margin: 0;
    }
  `
})

export const StyledMobileLeaderboard = styled(AdvertisingSlot)(({ theme }) => {
  return `
  margin: ${theme.spacing.husky} auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: ${AD_SLOTS.MOBILE_LEADERBOARD.WIDTH};
  height: ${AD_SLOTS.MOBILE_LEADERBOARD.HEIGHT};
`
})
