import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgListingStackIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1_18524_23170" fill="white">
        <rect x="4" y="4" width="16" height="7.11111" rx="0.5" />
      </mask>
      <rect
        x="4"
        y="4"
        width="16"
        height="7.11111"
        rx="0.5"
        stroke="#3E4153"
        stroke-width="2"
        mask="url(#path-1-inside-1_18524_23170)"
      />
      <mask id="path-2-inside-2_18524_23170" fill="white">
        <rect x="4" y="12.8887" width="16" height="7.11111" rx="0.5" />
      </mask>
      <rect
        x="4"
        y="12.8887"
        width="16"
        height="7.11111"
        rx="0.5"
        stroke="#3E4153"
        stroke-width="2"
        mask="url(#path-2-inside-2_18524_23170)"
      />
    </Icon>
  )
}
export default SvgListingStackIcon
