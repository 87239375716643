import { ListingStackIcon, MapPinLocationIcon } from '@kijiji/icons/src/icons'
import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'

import {
  MapViewButtonStyles,
  MapViewButtonText,
  MapViewFlexContainer,
} from '@/features/map/components/map-view-button/styled'

export type MapViewButtonProps = {
  onClick: () => void
  type: 'map' | 'list'
}

export const MapViewButton = ({ onClick, type }: MapViewButtonProps) => {
  const { t } = useTranslation(['srp'])

  const Icon =
    type === 'map' ? (
      <MapPinLocationIcon size="xsm" aria-hidden="true" />
    ) : (
      <ListingStackIcon size="xsm" aria-hidden="true" />
    )

  return (
    <MapViewButtonStyles
      shape="pill"
      size="compact"
      type="button"
      onClick={onClick}
      variant="subtle"
      data-testid="toggle-button"
    >
      <MapViewFlexContainer
        justifyContent="space-around"
        alignItems="center"
        gap={theme.spacing.mini}
        flexGrow={1}
      >
        {Icon}
        <MapViewButtonText>
          {t(type === 'map' ? 'map.srp.map_view_button' : 'map.srp.map_view_button_list')}
        </MapViewButtonText>
      </MapViewFlexContainer>
    </MapViewButtonStyles>
  )
}
