import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgSleeps: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.029 5.65a.35.35 0 00-.35.35v5.35H5.85V8.5a.65.65 0 01.65-.65h4.214a.65.65 0 01.65.65v2.85h1.272V8.5a.65.65 0 01.65-.65H17.5a.65.65 0 01.65.65v2.85h1.172V6a.35.35 0 00-.35-.35H5.028zm15.593 5.821v-5.47a1.65 1.65 0 00-1.65-1.65H5.028A1.65 1.65 0 003.379 6v5.47a1.65 1.65 0 00-1.029 1.53v4c0 .358.291.65.65.65h.379V19a.65.65 0 101.3 0v-1.35h14.643V19a.65.65 0 101.3 0v-1.35H21a.65.65 0 00.65-.65v-4a1.65 1.65 0 00-1.028-1.53zm-.272 4.88V13a.35.35 0 00-.35-.35H4a.35.35 0 00-.35.35v3.35h16.7zm-3.5-5v-2.2h-2.914v2.2h2.914zm-6.786 0v-2.2H7.15v2.2h2.914z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgSleeps
