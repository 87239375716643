import {
  GetFilterDrawerStateDocument,
  useGetFilterDrawerStateQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

export const useFilterDrawerState = () => {
  /** Read current value from the cache */
  const { data, client } = useGetFilterDrawerStateQuery()
  const { srp: { isFilterDrawerOpen = false, selectedAccordionItems = [] } = {} } = data ?? {}

  const setFilterDrawerState = useCallback(
    ({ isOpen, openItems }: { isOpen?: boolean; openItems?: string[] }) => {
      client.cache.writeQuery({
        query: GetFilterDrawerStateDocument,
        data: {
          srp: {
            ...data?.srp,
            isFilterDrawerOpen: isOpen ?? !!data?.srp.isFilterDrawerOpen,
            selectedAccordionItems: openItems ?? data?.srp.selectedAccordionItems ?? [],
          },
        },
      })
    },
    [client.cache, data]
  )

  return {
    setFilterDrawerState,
    isFilterDrawerOpen,
    selectedAccordionItems: selectedAccordionItems ?? [],
  }
}
