import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgRequestViewing: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.384 21h-2.826a.483.483 0 01-.357-.144.485.485 0 01-.143-.357c0-.141.048-.26.143-.356a.485.485 0 01.357-.143h2.826a.588.588 0 00.424-.192.589.589 0 00.192-.424v-8.768H5V14c0 .142-.048.26-.144.356a.485.485 0 01-.357.144.483.483 0 01-.356-.144A.485.485 0 014 14V6.616c0-.46.154-.845.463-1.153A1.565 1.565 0 015.615 5h1.77V3.308a.52.52 0 01.155-.384.525.525 0 01.386-.155.525.525 0 01.536.538V5h7.154V3.27c0-.142.047-.261.143-.357a.485.485 0 01.357-.144c.142 0 .26.048.356.144a.484.484 0 01.143.356V5h1.77c.46 0 .844.154 1.152.463.309.308.463.692.463 1.153v12.768c0 .46-.154.845-.462 1.154a1.565 1.565 0 01-1.154.462zm-8.017-1.5H2a.483.483 0 01-.356-.144.485.485 0 01-.144-.357c0-.141.048-.26.144-.356A.485.485 0 012 18.5h8.367l-2.72-2.765a.463.463 0 010-.673.457.457 0 01.34-.137c.138 0 .254.047.348.14l3.388 3.37c.162.161.242.35.242.565 0 .215-.08.404-.242.566l-3.389 3.388a.441.441 0 01-.34.133.505.505 0 01-.351-.156.451.451 0 01-.137-.331c0-.13.047-.241.14-.335l2.721-2.765z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgRequestViewing
