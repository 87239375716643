import { isAnyVacationRentalsCategory } from '@kijiji/category'
import {
  type AdditionalFlags,
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type AppliedToggleFilter,
  type AttributeFilter,
} from '@kijiji/generated/graphql-types'
import { spacing } from '@kijiji/theme'
import { type FC, Fragment, useCallback, useMemo, useRef } from 'react'

import { CategoryTreeFilter } from '@/components/srp/filters/filter-types/CategoryTreeFilter'
import { LocationTreeFilter } from '@/components/srp/filters/filter-types/LocationTreeFilter'
import { FilterGroup as FilterGroupComponent } from '@/components/srp/filters/FiltersAccordion/FilterGroup'
import { FiltersDivider } from '@/components/srp/filters/styled'
import { hasValuesProperty } from '@/components/srp/filters/utils'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { FILTER_CANONICAL } from '@/constants/search'
import { getSimplifiedFilters, separateTreesAndCleanFilters } from '@/domain/filters'
import { getExpandedFilterIdList } from '@/domain/srp/filters/getExpandedFilterIdList'
import { type ParentFilter } from '@/domain/srp/filters/getParentFilter'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import {
  type RefetchInput,
  type RefetchResultsType,
  FilterKeysEnum,
  useSearchActions,
} from '@/hooks/srp/useSearchActions'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { isToggleFilter } from '@/types/search'
import { Accordion, AccordionItem } from '@/ui/atoms/accordion'
import { Flex } from '@/ui/atoms/flex'
import { type NullableFields } from '@/utils/types'

type ClickableFilter = {
  onClickAccordionItem?: (id: string) => void
}

export type FilterProps<T, V> = {
  filter: T
  refetch: (args: AppliedFilter & NullableFields<V>, trackingLabel?: string) => void
  parentFilter?: ParentFilter
  isMobile?: boolean
  isSrpLoading?: boolean
} & ClickableFilter

export type TreeFilterProps = {
  filter: AttributeFilter
  selectedId?: number
  refetch: RefetchResultsType
} & ClickableFilter

export type FilterValuesUnion =
  | NullableFields<Omit<AppliedAttributeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateFilter, 'filterName'>>
  | NullableFields<Omit<AppliedDateRangeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedRangeFilter, 'filterName'>>
  | NullableFields<Omit<AppliedToggleFilter, 'filterName'>>

export type FilterRefetchResults<A> = (
  type: FilterKeysEnum,
  filterName: string,
  value: A,
  trackingLabel?: string
) => void

type AppliedAdditionalFlags = {
  [key: string]: AdditionalFlags[]
}

export enum FiltersAccordionOrigin {
  SIDE_PANEL = 'side_panel',
  QUICK_FILTER = 'quick_filter',
  MOBILE_MODAL = 'mobile_modal',
}

/**
 * FiltersAccordion component.
 *
 * This component renders an accordion for filtering search results. It supports both mobile and desktop views,
 * and allows for controlled and uncontrolled states for the accordion items.
 *
 * @param {boolean} [isMobile] - Indicates if the component is being viewed on a mobile device.
 * @param {string[]} [selectedAccordionItems] - List of selected accordion item IDs, if passed the component acts as a controlled state.
 * @param {(id: string) => void} [onClickAccordionItem] - Callback function when an accordion item is clicked.
 * @param {boolean} [isSimplified] - If true, the component will render a simplified version of the filters. Only the category and location filters will be shown.
 * @param {FiltersAccordionOrigin} [origin] - The origin of the component, used for tracking purposes.
 *
 * @returns {JSX.Element} The rendered FiltersAccordion component.
 */
export const FiltersAccordion: FC<{
  isMobile?: boolean
  selectedAccordionItems?: string[]
  onClickAccordionItem?: (id: string) => void
  isSimplified?: boolean
  origin: FiltersAccordionOrigin
}> = ({ isMobile, selectedAccordionItems, onClickAccordionItem, isSimplified, origin }) => {
  const { data } = useGetSearchResultsData()
  const { controls, searchQuery } = data || {}
  const { refetchResults } = useSearchActions()
  const appliedAdditionalFlags = useRef<AppliedAdditionalFlags>({})

  const selectedCategoryId = searchQuery?.category?.id || ALL_CATEGORIES_ID_NUM
  const isControlledState = selectedAccordionItems !== undefined
  const preExpandedFilters = useMemo(
    () => (isMobile ? [] : getExpandedFilterIdList(selectedCategoryId)),
    [isMobile, selectedCategoryId]
  )

  const filters = useMemo(() => controls?.filtering ?? [], [controls])
  const selectedLocationId = searchQuery?.location?.id ?? ALL_CANADA_LOCATION_ID
  const refetchResultsHandler: FilterRefetchResults<FilterValuesUnion> = useCallback(
    (type, filterName, value, trackingLabel) => {
      let refetchInput: Partial<RefetchInput> = {}

      if (type === FilterKeysEnum.ADDITIONAL_FLAG_FILTERS) {
        if (!hasValuesProperty(value)) return

        const newAppliedAdditionalFlags: AppliedAdditionalFlags = {
          ...appliedAdditionalFlags.current,
          [filterName]: (value.values as AdditionalFlags[]) || [],
        }
        appliedAdditionalFlags.current = newAppliedAdditionalFlags

        refetchInput = {
          [FilterKeysEnum.ADDITIONAL_FLAG_FILTERS]: Object.values(newAppliedAdditionalFlags).flat(),
        }
      } else {
        refetchInput = {
          [type]: [{ filterName, ...value }],
        }
      }

      // Added since priceType and price filters are mutually exclusive
      if (filterName === FILTER_CANONICAL.PRICE_RANGE) {
        refetchInput[FilterKeysEnum.ATTRIBUTE_FILTERS] = [
          { filterName: FILTER_CANONICAL.PRICE_TYPE, values: [] },
        ]
      }

      if (filterName === FILTER_CANONICAL.PRICE_TYPE) {
        refetchInput[FilterKeysEnum.RANGE_FILTERS] = [
          { filterName: FILTER_CANONICAL.PRICE_RANGE, minValue: null, maxValue: null },
        ]
      }

      refetchResults(refetchInput, {
        debounce: true,
        event: trackingLabel
          ? {
              action: GA_EVENT.FilterSelect,
              label: `${trackingLabel};cta=${origin}`,
            }
          : undefined,
      })
    },
    [origin, refetchResults]
  )

  const filtersToSanitize = useMemo(
    () => (isSimplified ? getSimplifiedFilters(filters) : filters),
    [filters, isSimplified]
  )
  const { categoryTree, locationTree, filterGroups } = separateTreesAndCleanFilters(
    filtersToSanitize,
    selectedCategoryId
  )

  return (
    <Accordion
      gap={0}
      value={selectedAccordionItems}
      preExpanded={isControlledState ? undefined : preExpandedFilters}
    >
      {categoryTree && (
        <CategoryTreeFilter
          filter={categoryTree}
          selectedId={selectedCategoryId}
          refetch={refetchResults}
          onClickAccordionItem={onClickAccordionItem}
        />
      )}

      <FiltersDivider />

      {locationTree && !isAnyVacationRentalsCategory(selectedCategoryId) && (
        <LocationTreeFilter
          filter={locationTree}
          selectedId={selectedLocationId}
          refetch={refetchResults}
          onClickAccordionItem={onClickAccordionItem}
        />
      )}

      {filterGroups.map((item) => {
        if (item.shouldShowTopLevelAccordion) {
          const selectedFilters = item.filters.filter(
            (filter) => isToggleFilter(filter) && filter.isSelected
          )

          let filterDescription = ''
          if (selectedFilters.length === 1) {
            filterDescription = selectedFilters[0].label
          } else if (selectedFilters.length > 1) {
            filterDescription = `${selectedFilters[0].label} + ${selectedFilters.length - 1}`
          }

          return (
            <Fragment key={item.name}>
              <FiltersDivider />
              <AccordionItem
                id={item.name}
                title={item.label ?? ''}
                description={filterDescription}
                onClick={onClickAccordionItem}
              >
                <Flex flexDirection="column" gap={spacing.default} data-testid="toggle-filter">
                  <FilterGroupComponent filterGroup={item} refetch={refetchResultsHandler} />
                </Flex>
              </AccordionItem>
            </Fragment>
          )
        }

        return (
          <FilterGroupComponent
            filterGroup={item}
            key={item.name}
            refetch={refetchResultsHandler}
            onClick={onClickAccordionItem}
          />
        )
      })}
    </Accordion>
  )
}
