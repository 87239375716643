import {
  type SearchResultsPage,
  useGetPopularRelatedKeywordsQuery,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { isValidCategory } from '@/domain/category/isValidCategory'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { DATALAYER_SRP_VIEW_TYPE } from '@/lib/ga/constants/datalayer'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type UseTrackingProps, useTracking } from '@/lib/ga/hooks/useTracking'
import { type DataLayerSearch } from '@/lib/ga/types/dataLayer'
import { trackEvent } from '@/lib/ga/utils'
import { getDatalayerSearch } from '@/lib/ga/utils/getDatalayerSearch'
import { getSearchImpressions } from '@/lib/ga/utils/getSearchImpressions'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'

/**
 * Custom fields for SRP-specific data layer
 */
export type SearchDatalayer = DataLayerSearch

const isSearchResultsType = (data: unknown): data is SearchResultsPage =>
  (data as SearchResultsPage)?.__typename === 'SearchResultsPage'

export const useSrpTracking = () => {
  const {
    asPath,
    query: { gpTopAds, bb },
  } = useRouter()

  const { data: searchData } = useGetSearchResultsData()

  const { searchQuery, results } = searchData ?? {}
  const { keywords, category } = searchQuery ?? {}

  const categoryId = category?.id ?? ALL_CATEGORIES_ID_NUM
  const searchLocation = getUserLocationFromSearchQuery(searchQuery)

  const { data } = useGetPopularRelatedKeywordsQuery({
    fetchPolicy: 'cache-only',
    skip: isValidCategory(categoryId),
    variables: {
      searchUrlPopularInput: {
        categoryId: categoryId.toString(),
        keywords,
        location: { id: searchLocation.id },
      },
    },
  })

  const searchPageData = getDatalayerSearch({
    asPath,
    searchPopularUrls: data?.searchUrlsPopular,
    searchData: isSearchResultsType(searchData) ? searchData : undefined,
  })

  const { mainListings = [], topListings = [] } = results || {}

  const trackViewPromotion: NonNullable<UseTrackingProps['extraPageLoadingTracking']> = useCallback(
    (baseDatalayer) => {
      /** LISTINGS IMPRESSIONS */
      const searchImpressions = getSearchImpressions({
        datalayerCategory: baseDatalayer.c,
        isSeeAllTopAd: !!gpTopAds,
        keywords: searchQuery?.keywords ?? '',
        listings: [...topListings, ...mainListings],
      })

      trackEvent({
        action: GA_EVENT.ViewPromotion,
        name: GA_EVENT.ViewPromotion,
        customParameters: { ecommerce: { items: searchImpressions } },
      })
    },
    [gpTopAds, mainListings, searchQuery?.keywords, topListings]
  )

  useTracking({
    categoryId: categoryId,
    locationId: searchLocation.id,
    pageData: searchPageData,
    pageType: getSearchPageType(keywords),
    searchView: bb ? DATALAYER_SRP_VIEW_TYPE.MAP : DATALAYER_SRP_VIEW_TYPE.LIST,
    searchPath: searchData?.searchQuery.seoUrl ?? asPath,
    extraPageLoadingTracking: trackViewPromotion,
  })
}
