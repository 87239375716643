import { createSrpGptConfig } from '@/components-page/srp/advertisement/createSrpGptConfig'
import { useSrpGpt } from '@/components-page/srp/advertisement/useSrpGpt'
import { extractCategoryIdsFromPath } from '@/domain/category/extractCategoryIdsFromPath'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { useAdSense } from '@/features/advertisement/hooks/useAdSense'
import { getAdSenseConfig } from '@/features/advertisement/utils/adsense/getAdSenseConfig'
import { cleanCategoryPathForDisplayAds } from '@/features/advertisement/utils/cleanCategoryPathForDisplayAds'
import { useGetSearchCategory } from '@/hooks/category/useGetSearchCategory'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useLocale } from '@/hooks/useLocale'
import { RemoteParamKeys, useExperiment } from '@/lib/firebase/hooks'

/**
 * Custom hook to handle the logic for SRP advertisements
 * returns configurations and targeting values to be passed to AdvertisingProvider
 *
 * @returns gpt - targeting values
 * @returns srpGptConfig - GPT configuration object
 */
export const useSrpAds = (isDesktopMapView: boolean, isMobileMapView: boolean) => {
  const { data } = useGetSearchResultsData()
  const { routeLocale } = useLocale()
  const { location } = useGetLocation()
  const { category } = useGetSearchCategory()

  const { searchQuery, pagination, controls } = data || {}

  const currentPage = getCurrentPageFromPagination(pagination || {})
  const cleanedPath = cleanCategoryPathForDisplayAds(category.path || [])
  const allCategoryIds = extractCategoryIdsFromPath(category.path || [])

  const experiment = useExperiment(RemoteParamKeys.SMALLER_MASTHEAD_TEST)
  const { enabled: isMastheadReduced, group, trackingKey } = experiment ?? {}

  const isMastheadReducedExpBucket = {
    experimentName: trackingKey,
    experimentGroup: isMastheadReduced && group === 1 ? 'b' : 'a',
  }

  const gpt = useSrpGpt({
    categoryIds: allCategoryIds,
    currentPage: currentPage,
    keywords: searchQuery?.keywords || '',
    locationId: location.id,
    routeLocale,
    filters: controls?.filtering || [],
    categories: cleanedPath,
    totalListings: pagination?.totalCount || 0,
    isDesktopMapView,
    isMobileMapView,
  })

  const srpGptConfig = createSrpGptConfig({
    gptTargeting: gpt,
    isMastheadReduced,
    isDesktopMapView,
  })

  const adSenseConfig = getAdSenseConfig({
    allCategoryIds: allCategoryIds,
    location,
    currentPage,
    keywords: searchQuery?.keywords || '',
    routeLocale,
    categoryPath: category.path || [],
    ...isMastheadReducedExpBucket,
  })

  useAdSense({
    ...adSenseConfig,
    active: true,
    containers: [
      { container: 'AFSTop', width: '100%', number: 2 },
      { container: 'AFSBottom', width: '100%', number: 2 },
    ],
  })

  return {
    srpGptConfig,
    gpt,
  }
}
