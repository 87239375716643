import BathsIcon from '@kijiji/icons/src/icons/Baths'
import BedsIcon from '@kijiji/icons/src/icons/Beds'
import LocationOutlineIcon from '@kijiji/icons/src/icons/LocationOutline'
import ParkingIcon from '@kijiji/icons/src/icons/Parking'
import PetsIcon from '@kijiji/icons/src/icons/Pets'
import SquareFootageIcon from '@kijiji/icons/src/icons/SquareFootage'
import UnitTypeIcon from '@kijiji/icons/src/icons/UnitType'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import {
  RealEstateListItem,
  VerticalDivider,
} from '@/components/srp/listing-card/real-estate-attributes/styled'
import { type RealEstateAttributes } from '@/components/srp/search-list/getRealEstateListingAttributes'
import { Spacing } from '@/ui/atoms/spacing'
import { List } from '@/ui/molecules/list'
import { Direction } from '@/ui/typings/direction'

export type RealEstateAttributesListProps = {
  attributes: RealEstateAttributes
  listingId: string
  testId?: string
  isMapCard?: boolean
}

export const RealEstateAttributesList = ({
  attributes: {
    nearestIntersection,
    bedrooms,
    bathrooms,
    unitType,
    parkingSpots,
    squareFootage,
    petsAllowed,
  },
  testId = 're-attributes-list',
  isMapCard = false,
}: RealEstateAttributesListProps) => {
  const { spacing } = useTheme()
  const { t } = useTranslation('srp')

  return (
    <>
      <Spacing mTop={spacing.defaultSmall} />
      <List variant={Direction.horizontal} data-testid={testId}>
        {!!nearestIntersection && !isMapCard && (
          <RealEstateListItem
            size="small"
            aria-label={t('listing.realestate.nearest_intersection.label')}
          >
            <LocationOutlineIcon size="sm" width={spacing.large} aria-hidden />
            {nearestIntersection}
            <VerticalDivider role="separator" />
          </RealEstateListItem>
        )}

        {/* Number of bedrooms */}
        {!!bedrooms && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.bedrooms.label')}>
            <BedsIcon size="sm" width={spacing.large} aria-hidden />
            {bedrooms}
          </RealEstateListItem>
        )}

        {/* Number of bathrooms */}
        {!!bathrooms && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.bathrooms.label')}>
            <BathsIcon size="sm" width={spacing.large} aria-hidden />
            {bathrooms}
          </RealEstateListItem>
        )}

        {/* Unit type */}
        {!!unitType && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.unit_type.label')}>
            <UnitTypeIcon size="sm" width={spacing.large} aria-hidden />
            {t(`listing.realestate.unit_type.${unitType}`)}
          </RealEstateListItem>
        )}

        {/* Number of parking spots */}
        {!!parkingSpots && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.parking.label')}>
            <ParkingIcon size="sm" width={spacing.large} aria-hidden />
            {parkingSpots}
          </RealEstateListItem>
        )}

        {/* Squarefootage is only shown if it is >1 and not undefined */}
        {!!squareFootage && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.size.label')}>
            <SquareFootageIcon size="sm" width={spacing.large} aria-hidden />
            {squareFootage} {t('listing.realestate.size.units')}
          </RealEstateListItem>
        )}

        {/* Only show pets attribute if allowed */}
        {!!petsAllowed && (
          <RealEstateListItem size="small" aria-label={t('listing.realestate.pets_allowed.label')}>
            <PetsIcon size="sm" width={spacing.large} aria-hidden />
            {t('listing.realestate.pets_allowed')}
          </RealEstateListItem>
        )}
      </List>
    </>
  )
}
