import {
  GetListingsDrawerStateDocument,
  useGetListingsDrawerStateQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

export const useListingsDrawerState = () => {
  /** Read current value from the cache */
  const { data, client } = useGetListingsDrawerStateQuery()
  /** TODO: Right now, this is the default for all SRP's but only applies when <DrawerDraggable /> is present */
  const { srp: { isListingsDrawerOpen = true } = {} } = data ?? {}

  const setListingsDrawerState = useCallback(
    ({ isOpen }: { isOpen?: boolean }) => {
      client.cache.writeQuery({
        query: GetListingsDrawerStateDocument,
        data: {
          srp: {
            ...data?.srp,
            isListingsDrawerOpen: isOpen ?? !!data?.srp.isListingsDrawerOpen,
          },
        },
      })
    },
    [client.cache, data]
  )

  return {
    setListingsDrawerState,
    isListingsDrawerOpen,
  }
}
