import { isDealerListing } from '@/features/listing/utils/isDealerListing'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getSearchImpressions } from '@/lib/ga/utils/getSearchImpressions'
import { type Listing } from '@/types/search'

type TrackEventListingClickProps = {
  searchView?: string
  isSeeAllTopAd?: boolean
  keywords?: string
  listing: Listing
  position: number
  photoshowcaseClick?: boolean
}

/**
 * Push this user triggered event to the data layer
 * This correlated to "ResultsAdClick" on legacy. It is called select_promotion on NWA.
 * This event is triggered when a user clicks on a listing to go to its VIP
 */
export const trackEventListingClick = ({
  searchView,
  isSeeAllTopAd,
  keywords,
  listing,
  position,
  photoshowcaseClick,
}: TrackEventListingClickProps) => {
  const { adSource, id } = listing

  const isDealer = isDealerListing(listing.attributes)

  const searchImpressions = getSearchImpressions({
    isSeeAllTopAd,
    keywords: keywords,
    listings: [listing],
  })

  trackEvent({
    action: GA_EVENT.SelectPromotion,
    name: GA_EVENT.SelectPromotion,
    label: `partner=${adSource};partneradid:${id};position:${position};isDealerListing=${isDealer};PhotoshowcaseClick=${
      // to protect against event object getting passed down instead of boolean
      photoshowcaseClick === true ? '1' : '0'
    }`,
    customParameters: { ecommerce: { items: searchImpressions }, searchView: searchView ?? null },
  })
}
