import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgCity: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.55 19.3v-4.4h-.975a.648.648 0 01-.474-.201.65.65 0 01-.201-.475c0-.183.067-.34.2-.474a.65.65 0 01.475-.2H6.6a4.8 4.8 0 011.488-3.15A5.542 5.542 0 0111.3 8.85V5.377c0-.301.105-.556.316-.764a1.04 1.04 0 01.759-.313h3.65c.296 0 .549.105.76.316.21.21.315.463.315.76v1.099c0 .296-.106.549-.319.76a1.05 1.05 0 01-.766.315H12.65v1.3c1.25.167 2.32.683 3.213 1.55a4.8 4.8 0 011.487 3.15h1.025c.183 0 .34.067.474.201a.65.65 0 01.201.475c0 .183-.067.34-.2.474a.649.649 0 01-.475.2H17.4v4.4h2.625c.183 0 .34.067.474.201a.65.65 0 01.201.475c0 .183-.067.34-.2.474a.649.649 0 01-.475.2h-16.1a.648.648 0 01-.474-.201.65.65 0 01-.201-.475c0-.183.067-.34.2-.474a.65.65 0 01.475-.2H6.55zm1.35 0h3.4v-4.4H7.9v4.4zm4.75 0h3.4v-4.4h-3.4v4.4zM8 13.55h7.95a3.637 3.637 0 00-1.25-2.425c-.733-.65-1.617-.975-2.65-.975a4.14 4.14 0 00-2.725.975C8.542 11.775 8.1 12.583 8 13.55z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgCity
