import { useRef } from 'react'

import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'
import { useAdIframe } from '@/features/advertisement/hooks/useAdIframe'
import { useScrollAway } from '@/features/advertisement/hooks/useScrollAway'
import { RemoteParamKeys, useExperiment } from '@/lib/firebase/hooks'

export const useTopLeaderboardAd = () => {
  const ref = useRef<HTMLDivElement>(null)

  // generate a new key when the screen size changes to force a new ad request
  const topLeaderboardKey = GPT_COMPONENT_ID.TOP_LEADERBOARD

  const { handleInViewChange, isSticky, scrollAway } = useScrollAway()
  const { adWidth, rootMargin, stickyOffset } = useAdIframe(ref)

  const experiment = useExperiment(RemoteParamKeys.SMALLER_MASTHEAD_TEST)
  const { enabled, group } = experiment ?? {}
  const isMastheadReduced = enabled === true && group === 1

  return {
    scrollAway,
    isSticky,
    topLeaderboardKey,
    adWidth,
    rootMargin,
    stickyOffset,
    handleInViewChange,
    ref,
    isMastheadReduced,
  }
}
