import styled from 'styled-components'
import { Drawer as DraggableDrawer } from 'vaul'

export const DraggableDrawerContent = styled(DraggableDrawer.Content)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  padding: ${theme.spacing.default};
  position: fixed;
  outline: none;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;

  height: fit-content;
  max-height: 95vh;
  border-radius: ${theme.borderRadius.large} ${theme.borderRadius.large} 0 0;
  flex-direction: column;
`
)

export const DraggableDrawerIndicator = styled.span(
  ({ theme }) => `
  display: block;
  width: 48px;
  min-height: 6px;
  background-color: ${theme.colors.grey.light2};
  border-radius: ${theme.borderRadius.small};
  margin: 0 auto;
`
)

export const DraggableDrawerChildren = styled.div`
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export const DraggableDrawerBackdrop = styled(DraggableDrawer.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`
