import { ThemeProps } from '@kijiji/theme/src'
import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import {
  COMMON_INPUT_STYLE,
  INPUT_BORDER_COLOR,
  INPUT_ERROR_COLOR,
  INPUT_HORIZONTAL_PADDING,
} from '@/ui/constants/commonFormStyle'

import { SelectVariant } from './Select'

type SelectButtonProps = {
  hasError?: boolean
  hasSelectedIcon?: boolean
  isGrouped?: boolean
  variant: SelectVariant
}

const SELECT_SHADOW = '0 0 .8rem rgba(38, 129, 219, 0.5);'

export const SelectButton = styled.div<SelectButtonProps>(
  ({ theme, hasError, isGrouped, hasSelectedIcon, variant }) => `
  ${COMMON_INPUT_STYLE(theme)}
  align-items: center;
  border-color: ${
    // prettier-ignore
    isGrouped ? 'transparent' : hasError ? INPUT_ERROR_COLOR(theme) : INPUT_BORDER_COLOR(theme)
  };
  display: flex;
  height: ${variant === SelectVariant.ROUNDED ? '4rem' : '4.8rem'};
  justify-content: space-between;
  left: 0;
  padding: 0 ${INPUT_HORIZONTAL_PADDING};
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  ${variant === SelectVariant.ROUNDED ? `border-radius: ${theme.borderRadius.rounded};` : ''}
  
  & > span:first-of-type {
   ${variant === SelectVariant.ROUNDED ? 'margin-top: 0;' : !hasSelectedIcon ? `margin-top: ${theme.spacing.defaultSmall};` : ''}
   ${variant === SelectVariant.ROUNDED ? ' align-items: center' : 'align-items: baseline'};

   display: flex;
   overflow: hidden;
    
   white-space: nowrap;

    & > svg {
      margin-top: ${theme.spacing.mini};
      margin-right: ${theme.spacing.defaultSmall};
    }

    span {
      margin-left: ${theme.spacing.defaultSmall};
    }
  }

  &[aria-expanded="true"]{
    ${
      variant === SelectVariant.DEFAULT
        ? `box-shadow: ${SELECT_SHADOW};
           border: .1rem solid ${theme.colors.blue.primary};
           border-radius: ${theme.borderRadius.small} ${theme.borderRadius.small} 0 0;`
        : ''
    }
  }
`
)

type SelectButtonContentProps = {
  variant: SelectVariant
}

export const SelectButtonContent = styled.span<SelectButtonContentProps>(
  ({ theme, variant }) => `
  ${variant === SelectVariant.ROUNDED ? `color: ${theme.colors.grey.primary};` : ''} 
`
)

type LabelContainerProps = {
  hasCustomTrigger?: boolean
  hasSelectedIcon?: boolean
  variant: SelectVariant
}

export const LabelContainer = styled.div<LabelContainerProps>(
  ({ theme, hasCustomTrigger, hasSelectedIcon, variant }) => `
  align-items: center;
  display: flex;
  height: ${variant === SelectVariant.DEFAULT ? '4.8rem' : '4rem'};
  justify-content: ${hasCustomTrigger ? 'end' : 'space-between'};
  padding: 0 ${INPUT_HORIZONTAL_PADDING};
  width: 100%;

  label {
    ${hasSelectedIcon ? 'left: 4.8rem' : ''} ;
  }

  span svg {
    height: ${variant === SelectVariant.DEFAULT ? theme.spacing.large : '1.8rem'};
    width: ${variant === SelectVariant.DEFAULT ? theme.spacing.large : '1.8rem'};
  }
`
)

type SelectListProps = {
  maxHeight?: string
}

export const SelectList = styled.ul<SelectListProps>(
  ({ theme, maxHeight }) => `
  max-height: ${maxHeight || 'unset'};
  background: ${theme.colors.white};

  list-style-type: none;
  overflow-y: auto;
`
)

type SelectListWrapperProps = {
  hasError?: boolean
  isFocused?: boolean
  isOpen?: boolean
  variant: SelectVariant
}

const getBorderSelectList = ({
  isOpen,
  hasError,
  variant,
  theme,
}: Pick<SelectListWrapperProps, 'hasError' | 'isOpen' | 'variant'> & {
  theme: ThemeProps
}) => {
  if (isOpen) {
    if (hasError) return INPUT_ERROR_COLOR(theme)
    if (variant === SelectVariant.DEFAULT) return theme.colors.blue.primary
    return INPUT_BORDER_COLOR(theme)
  }

  return 'transparent'
}

export const SelectListWrapper = styled.span<SelectListWrapperProps>(
  ({ hasError, isOpen, theme, variant }) => `
  overflow: hidden;
  border: .1rem solid ${getBorderSelectList({ isOpen, hasError, theme, variant })};

  ${isOpen && variant === SelectVariant.DEFAULT ? `box-shadow: ${SELECT_SHADOW};` : ''}
  ${isOpen && variant === SelectVariant.ROUNDED ? `margin-top: ${theme.spacing.defaultSmall}` : ''};
  ${variant === SelectVariant.ROUNDED ? `border-radius: ${theme.borderRadius.medium}` : `border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small}`};
  ${variant === SelectVariant.ROUNDED ? '' : 'border-top: none;'}
`
)

type SelectListItemProps = {
  isHighlighted?: boolean
  isDisabled?: boolean
  variant: SelectVariant
}

export const SelectListItem = styled(Flex)<SelectListItemProps>(
  ({ theme, isHighlighted, isDisabled, variant }) => `
  background: ${isHighlighted ? theme.colors.grey.light4 : theme.colors.white};
  cursor: pointer;
  padding: ${theme.spacing.default};
  ${variant === SelectVariant.ROUNDED ? `color: ${theme.colors.grey.primary};` : ''} 

  ${
    isDisabled &&
    `
      pointer-events:none; // disables mouse/touch events including CSS hover/active states, click/tap events in Javascript
      opacity:0.6;

      p {
        font-style: italic;
      }
    `
  }

  svg {
    height: ${theme.spacing.large};
    margin-right: ${theme.spacing.default};
    width: ${theme.spacing.large};
  }
`
)
