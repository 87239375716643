import { useTranslation } from 'next-i18next'
import { type FC, type PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { ClientRender } from '@/components/shared/client-render'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getListingSortingDateLabel } from '@/features/listing/utils/getListingSortingDateLabel'
import { type BodyTextProps, BodyText } from '@/ui/atoms/body-text'

type RelativeTimeProps = PropsWithChildren &
  BodyTextProps & {
    /**
     * Specifies the relative date to be compared with "now"
     * */
    date: string

    /**
     * Specifies the translation format for date labels
     *
     * @example "default" - 1 hr ago
     * @example "short" - 1 hr
     * */
    variant?: 'default' | 'short'
  }

export const RelativeTime: FC<RelativeTimeProps> = ({
  date,
  children,
  variant = 'default',
  ...rest
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])

  const dateLabel = date ? getListingSortingDateLabel(new Date(date), variant === 'short') : null

  if (!dateLabel) return null

  /* Dates cause hydration errors since timezone is different between client and server */
  return (
    <ClientRender>
      <BodyText
        aria-label={t('listing:activation_time.aria_label', { activationText: dateLabel.key })}
        color={colors.grey.light1}
        data-testid="listing-date"
        size={{ small: 'xSmall', medium: 'small' }}
        {...rest}
      >
        {children} {t(dateLabel.key, { ...dateLabel.params })}
      </BodyText>
    </ClientRender>
  )
}
