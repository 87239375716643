import { type FC } from 'react'
import { InView } from 'react-intersection-observer'

import { TopLeaderboardAdContainer } from '@/components-page/srp/advertisement/styled'
import { TopSlotWrapper } from '@/components-page/srp/advertisement/TopSlotWrapper'
import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'
import { useTopLeaderboardAd } from '@/features/advertisement/hooks/useTopLeaderboardAd'

type TopLeaderboardAdSlotProps = {
  isMobileForAds?: boolean
}

export const TopLeaderboardAdSlot: FC<TopLeaderboardAdSlotProps> = ({ isMobileForAds }) => {
  const {
    stickyOffset,
    scrollAway,
    topLeaderboardKey,
    isSticky,
    rootMargin,
    handleInViewChange,
    ref,
    isMastheadReduced,
  } = useTopLeaderboardAd()

  // need to ensure we remove ads from the dom when they are not intended to be viewed.
  if (isMobileForAds) {
    return null
  }

  return (
    <>
      <InView
        onChange={handleInViewChange}
        rootMargin={rootMargin}
        data-testid={`${GPT_COMPONENT_ID.TOP_LEADERBOARD}-in-view`}
      />
      <TopLeaderboardAdContainer
        ref={ref}
        isSticky={isSticky}
        stickyOffset={stickyOffset}
        shouldScrollAway={scrollAway}
        isMastheadReduced={isMastheadReduced}
      >
        <TopSlotWrapper
          id={GPT_COMPONENT_ID.TOP_LEADERBOARD}
          data-testid={GPT_COMPONENT_ID.TOP_LEADERBOARD}
          key={topLeaderboardKey}
        />
      </TopLeaderboardAdContainer>
    </>
  )
}
