import { type Session } from 'next-auth'

import { type DataLayerBase } from '@/lib/ga/types/dataLayer'
import { getHashedUserIdFromCookie } from '@/utils/cookies/getHashedUserIdFromCookie'

/**
 * Returns the user object of the the datalayer
 *
 * @param session - User Session
 * @param categoryId - The Category ID could influence in the test group array
 * @returns
 */
export const getDatalayerUser = async (
  session?: Session | null,
  categoryId?: number,
  experiments: string = ''
): Promise<Pick<DataLayerBase, 'u'>> => {
  const isLoggedIn = !!session?.user
  const accountType = session?.user?.type

  return {
    u: {
      at: accountType,
      huid: isLoggedIn ? getHashedUserIdFromCookie() : undefined,
      li: isLoggedIn,
      tg: { stg: experiments },
    },
  }
}
