import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { TextLink } from '@/ui/atoms/text-link'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

/** Style to be added to the navigation when we the breadcrumbs should appear in a single line */
const noLineWrapStyle = (theme: ThemeProps) => `
  display: block;
  overflow: hidden;
  position: relative;
  text-overflow: clip;
  white-space: nowrap;
  white-space: nowrap;

  & > ol {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    overflow: hidden;

    & > li {
      display: inline;
      white-space: nowrap;

      & > a,
      & > p {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  ${MEDIA_QUERIES(theme).medium} {
    & > ol {
      width: fit-content;
    }
  }
`

export const BreadcrumbsNavigation = styled.nav<{ preventLineWrap?: boolean }>(
  ({ theme, preventLineWrap }) => `
  ${preventLineWrap ? noLineWrapStyle(theme) : 'display: flex;'}
`
)

export const BreadcrumbsOverflowEllipsis = styled.button(
  ({ theme }) => `
  align-items: center;
  background: linear-gradient(270deg, hsla(0, 0%, 100%, 0) 0, ${theme.colors.white} 5rem);
  bottom: 0;
  display: flex;
  gap: 0.2rem;
  left: 0;
  padding-right: 6rem;
  position: absolute;
  top: 0;
  z-index: 1;

  & > span {
    background:${theme.colors.grey.light2};
    border-radius: ${theme.borderRadius.rounded};
    height: .8rem;
    width: .8rem;
  }


  ${MEDIA_QUERIES(theme).medium} {
    display: none;
  }
`
)

export const BreadcrumbList = styled.ol(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.mini};
  list-style: none;
  padding: 0;
`
)

export const BreadcrumbLink = styled(TextLink)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  gap: ${theme.spacing.mini};

  & svg {
    height: auto;
    width: ${theme.spacing.large};
  }

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.small)}
  }
`
)

export const BreadcrumbText = styled(BodyText)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  gap: ${theme.spacing.mini};
  
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.small)}
  }
`
)

export const BreadcrumbChevronContainer = styled.div(
  ({ theme }) => `
  display: inline;
  width: ${theme.spacing.large};
  height: ${theme.spacing.large}; 
  
  & svg {
    height: auto;
    width: ${theme.spacing.large};
  }
`
)
