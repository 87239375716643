import { isAnyRealEstateCategory } from '@kijiji/category'

import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'

export const useMapSRP = (categoryId: number): boolean => {
  const toggle = useToggle(RemoteParamKeys.MAP_SRP)
  const enabled = toggle?.enabled ?? false

  const isMapSRPEnabled: boolean = enabled && isAnyRealEstateCategory(categoryId)

  return isMapSRPEnabled
}
