import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { FilterMobileChip } from '@/components/srp/filters/FilterMobileChip'
import { FiltersModal } from '@/components/srp/filters/FiltersModal'
import { SaveSearchGuestModal } from '@/components/srp/save-search'
import { SavedSearchActionFeedback } from '@/components/srp/save-search/SavedSearchActionFeedback'
import {
  SearchButtonsWrapper,
  SearchFloatingContainerMapSRP,
} from '@/components/srp/search-floating-buttons/styled'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { useFilterDrawerState } from '@/hooks/srp/useFilterDrawerState'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useListingsDrawerState } from '@/hooks/srp/useListingsDrawerState'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { Flex } from '@/ui/atoms/flex'

type SearchFloatingButtons = {
  isMobileForAds?: boolean
  isPartiallyOpen?: boolean
}

// TODO KJCA-4771: That component was a quick fix for the drawer overlapping issue
// so this file should be removed after that is fixed on the original component
export const SearchFloatingButtonsMapSrp: FC<SearchFloatingButtons> = ({ isPartiallyOpen }) => {
  const { spacing } = useTheme()
  const { data: { searchQuery } = {} } = useGetSearchResultsData()
  const { isFilterDrawerOpen, setFilterDrawerState } = useFilterDrawerState()
  const { setListingsDrawerState } = useListingsDrawerState()
  const keywords = searchQuery?.keywords || ''
  const searchString = searchQuery?.searchString || ''
  const appliedFiltersCount =
    (searchQuery?.filters?.length ?? 0) +
    (searchQuery?.category?.id === ALL_CATEGORIES_ID_NUM ? 0 : 1)
  const {
    activateError,
    deactivateError,
    handleCloseModal: handleSaveSearchModalClose,
    isModalOpen: isSaveSearchModalOpen,
    lookupLoading,
    showUserFeedback,
    signInRedirectUrl,
  } = useSaveSearchActions({ searchString })

  const handleFilterChipClick = () => {
    setFilterDrawerState({ isOpen: true })
  }
  const handleCloseFiltersModal = () => {
    setListingsDrawerState({ isOpen: true })
    setFilterDrawerState({ isOpen: false })
  }

  return (
    <>
      <SaveSearchGuestModal
        error={!!activateError || !!deactivateError}
        handleCloseModal={handleSaveSearchModalClose}
        isModalOpen={isSaveSearchModalOpen}
        keywords={keywords}
        signInRedirectUrl={signInRedirectUrl}
      />
      <FiltersModal isOpen={isFilterDrawerOpen} onClose={handleCloseFiltersModal} />
      <SearchFloatingContainerMapSRP isPartiallyOpen={isPartiallyOpen}>
        {
          <SearchButtonsWrapper
            flexDirection="column"
            gap={spacing.defaultSmall}
            alignItems="center"
            justifyContent="center"
          >
            {/* to prevent CLS, we nned to wait for the lookup so we know whether to display 1 or 2 buttons */}
            {!lookupLoading && (
              <Flex gap={spacing.default}>
                {!isFilterDrawerOpen && (
                  <FilterMobileChip
                    onClick={handleFilterChipClick}
                    appliedFiltersCount={appliedFiltersCount}
                  />
                )}
              </Flex>
            )}
          </SearchButtonsWrapper>
        }
      </SearchFloatingContainerMapSRP>
      {showUserFeedback && <SavedSearchActionFeedback type={showUserFeedback} />}
    </>
  )
}
