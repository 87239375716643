import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ShowWhenSmaller } from '@/components/shared/breakpoint'
import { RelativeTime } from '@/components/shared/relative-time/RelativeTime'
import {
  ListingDetailsContainer,
  SortingDateLabel,
  VerticalDivider,
} from '@/components/srp/listing-card/listing-details/styled'
import { ListingBadgeIcons } from '@/components/srp/listing-card/ListingCard'
import { ShippingBadge } from '@/components/srp/listing-card/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'

export enum ListingDetailsDividerStyle {
  VERTICAL = 'vertical',
  DOT = 'dot',
}

export type ListingDetailsProps = {
  /**
   * Divider style for listing details
   */
  dividerStyle?: ListingDetailsDividerStyle
  /**
   * Sorting date for the listing
   */
  sortingDate?: string | null
  /**
   * Listing location name
   */
  locationName?: string | null
  /**
   * Defines if this listing will be shipped by seller
   */
  isShippedBySeller?: boolean
  /**
   * Ad source
   */
  listingAdSource?: string
  /**
   * Distance from the search location, in metres.
   */
  proximity?: string
  /**
   * Defines if the date should be formatted to short format. E.g: "< 1 min" or "4 d"
   */
  isMobileFormat?: boolean
}

export const ListingDetails: FC<ListingDetailsProps> = ({
  dividerStyle = ListingDetailsDividerStyle.VERTICAL,
  sortingDate,
  isShippedBySeller,
  listingAdSource,
  locationName,
  proximity,
  isMobileFormat,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])

  // Should not return the  component if listing doesn't have any details defined
  if (!locationName && !sortingDate) return null

  const hasTopAdLabelEnabled = listingAdSource === 'TOP_AD'

  const getDivider = () => {
    if (dividerStyle === ListingDetailsDividerStyle.DOT) {
      return (
        <BodyText
          color={colors.grey.light3}
          as="span"
          role="separator"
          data-testid="dot-divider"
          size="xSmall"
        >
          •
        </BodyText>
      )
    }

    return <VerticalDivider role="separator" data-testid="vertical-divider" />
  }

  return (
    <ListingDetailsContainer data-testid="listing-details">
      {locationName ? (
        <BodyText color={colors.grey.light1} data-testid="listing-location" size="xSmall">
          {locationName}
        </BodyText>
      ) : null}

      {proximity ? (
        <>
          {locationName ? getDivider() : null}

          <BodyText color={colors.grey.light1} data-testid="listing-proximity" size="xSmall">
            {proximity}
          </BodyText>
        </>
      ) : null}

      <SortingDateLabel hasTopProvAdEnabled={hasTopAdLabelEnabled}>
        {sortingDate ? (
          <>
            {locationName || proximity ? getDivider() : null}

            <RelativeTime
              color={colors.grey.light1}
              data-testid="listing-date"
              date={sortingDate}
              size="xSmall"
              variant={isMobileFormat ? 'short' : 'default'}
            />
          </>
        ) : null}
      </SortingDateLabel>

      {isShippedBySeller && (
        <>
          {(locationName || sortingDate) && (
            <ShowWhenSmaller breakpoint="medium">{getDivider()}</ShowWhenSmaller>
          )}
          <ShowWhenSmaller breakpoint="medium" data-testid={'shipping-badge-mweb'}>
            <ShippingBadge as="div">
              {ListingBadgeIcons.shippedBySeller}
              <span>{t(`listing:flag.shipping`)}</span>
            </ShippingBadge>
          </ShowWhenSmaller>
        </>
      )}
    </ListingDetailsContainer>
  )
}
