import { breadcrumbsSeo } from '@kijiji/seo/configs/breadcrumbsSeo'
import Script from 'next/script'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode, useEffect, useState } from 'react'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { Skeleton } from '@/ui/atoms/skeleton'
import {
  type BreadcrumbsProps as UIBreadcrumbsProps,
  Breadcrumbs as UIBreadcrumbs,
} from '@/ui/molecules/breadcrumbs'

export type BreadcrumbsProps = Pick<UIBreadcrumbsProps, 'shouldPreventLineWrap'> & {
  breadcrumbs: UIBreadcrumbsProps['crumbs']
  loading?: boolean
  pageName: string
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  loading,
  pageName,
  shouldPreventLineWrap,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.COMMON)

  const JSON_LD_BREADCRUMBS_ID = `${pageName}-breadcrumbs-json-ld`

  const getCrumbsScriptComponent = (crumbs?: UIBreadcrumbsProps['crumbs']): ReactNode => {
    if (!crumbs?.length) return null

    const crumbJSON = breadcrumbsSeo(crumbs)

    return (
      <Script
        key={JSON_LD_BREADCRUMBS_ID}
        id={JSON_LD_BREADCRUMBS_ID}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(crumbJSON) }}
      />
    )
  }

  const [jsonLdScript] = useState<ReactNode>(getCrumbsScriptComponent(breadcrumbs))

  /** Update breadcrumbs JSON-LD on breadcrumbs change */
  useEffect(() => {
    const breadcrumbJSON = breadcrumbsSeo(breadcrumbs)
    const script = document.getElementById(JSON_LD_BREADCRUMBS_ID)

    if (script) {
      script.innerHTML = JSON.stringify(breadcrumbJSON)
    }
  }, [JSON_LD_BREADCRUMBS_ID, breadcrumbs])

  if (loading || !breadcrumbs) return <Skeleton width="30%" />

  /** The component shouldn't take the top space if it is empty */
  if (!breadcrumbs?.length) return null

  return (
    <>
      {jsonLdScript}

      <UIBreadcrumbs
        crumbs={breadcrumbs}
        ellipsisLabel={t('common:breadcrumb.ellipsis.label')}
        label={t('common:breadcrumb.label')}
        shouldPreventLineWrap={shouldPreventLineWrap}
      />
    </>
  )
}
