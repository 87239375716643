import { CATEGORIES, isBuyAndSellCategory } from '@kijiji/category'
import {
  GetPendingSearchInputDocument,
  GetUserLocaleDocument,
  GetUserLocationDocument,
} from '@kijiji/generated/graphql-types'
import { Spacing } from '@kijiji/ui'
import { type GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { PageContainer } from '@/components/shared/page-container'
import { RadiusBoostSystemMessage } from '@/components/shared/radius-boost-system-message/RadiusBoostSystemMessage'
import { SrpGrid, SrpHeader, SrpResultsSort } from '@/components/srp'
import { FiltersSidebar } from '@/components/srp/filters/FiltersSidebar'
import { TicketListingCard } from '@/components/srp/listing-card/ticket-listing-card/TicketListingCard'
import { SearchFloatingButtons } from '@/components/srp/search-floating-buttons'
import { SearchList } from '@/components/srp/search-list'
import { SrpBreadcrumb } from '@/components/srp/srp-breadcrumb/SrpBreadcrumb'
import { SrpError } from '@/components/srp/srp-error'
import { TopLeaderboardAdSlot } from '@/components-page/srp/advertisement/TopLeaderboardAdSlot'
import { SearchAdsWrapper } from '@/components-page/vip/advertisement/SearchAdsWrapper'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getSearchCategoryFromSearchQuery } from '@/domain/category/getSearchCategoryFromSearchQuery'
import { debugServerTime } from '@/domain/debugServerTiming'
import { initializePageServer } from '@/domain/initializePageServer'
import { getLocale } from '@/domain/locale'
import { getUserLocationFromSearchQuery } from '@/domain/location/getUserLocationFromSearchQuery'
import { getCurrentPageFromPagination } from '@/domain/srp/getCurrentPageFromPaginationData'
import { getSrpComposedUrl } from '@/domain/srp/getSrpComposedUrl'
import { getSrpContentfulData } from '@/domain/srp/getSrpContentfulData'
import {
  type GetSrpPropsWithCache,
  type PageRedirect,
  getSrpLoadData,
} from '@/domain/srp/getSrpLoadData'
import { redirectLocationlessUrl } from '@/domain/srp/page-redirects/redirectLocationlessUrl'
import { AdSense } from '@/features/advertisement/components/adsense'
import ClarivoyScript from '@/features/advertisement/components/clarivoy/clarivoyScript'
import useClarivoy from '@/features/advertisement/components/clarivoy/useClarivoy'
import { useFavouriteOnLoginCallback } from '@/features/listing/hooks/useFavouriteOnLoginCallback'
import { MapProvider } from '@/features/map/components/MapProvider'
import { useMapSRP } from '@/features/map/hooks/useMapSRP'
import { getMenuPrefetch } from '@/features/navigation/api/getMenuPrefetch'
import { ProductCarouselSeoJsonLd } from '@/features/seo/components/ProductCarouselSeoJsonLd'
import { SeoMetadata } from '@/features/seo/components/SeoMetadata'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchLoadingState } from '@/hooks/srp/useSearchLoadingState'
import { useChameleon } from '@/hooks/useChameleon'
import { useExtendedSearch } from '@/hooks/useExtendedSearch'
import { useLocale } from '@/hooks/useLocale'
import { useSaveSearchFromLogin } from '@/hooks/useSaveSearchFromLogin'
import { RemoteParamKeys } from '@/lib/firebase/config'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { useSrpTracking } from '@/lib/ga/hooks/useSrpTracking'
import profile from '@/lib/metrics/profileWrapper'
import { type BasePageProps } from '@/pages/_app'
import { type IntlResponse, isIntlResponse } from '@/types/translations'
import { Flex } from '@/ui/atoms/flex'
import { getUserDevice } from '@/utils/getUserDevice'
import { sendToLogger } from '@/utils/sendToLogger'

const BaseLeaderboardAdSlot = dynamic(
  () =>
    import('@/components-page/srp/advertisement/BaseLeaderboardAdSlot').then(
      (mod) => mod.BaseLeaderboardAdSlot
    ),
  { ssr: false }
)

const ContentfulContent = dynamic(
  () =>
    import('@/components/shared/contentful-content/ContentfulContent').then(
      (mod) => mod.ContentfulContent
    ),
  { ssr: false }
)

const MapSRP = dynamic(() => import('@/features/map').then((mod) => mod.MapSRP), {
  ssr: false,
})

export type SRPErrors = {
  hasServerError?: boolean
  hasResultsError?: boolean
}

export interface SearchPageServerProps extends BasePageProps {
  /**
   * Error states from SRP Anvil calls
   */
  errors?: SRPErrors
  /**
   * An ID that is used to identify the page load. This allows us to detect new
   * page loads where the url hasn't changed.
   */
  pageLoadId: string
}

const SearchPage = ({ pageLoadId, userAgent, errors: serverErrors }: SearchPageServerProps) => {
  const { setLoadingStates } = useSearchLoadingState()
  const [hasClientFetchAttemptError, setHasClientFetchAttemptError] = useState(false)
  const { t } = useTranslation(['srp', 'filters'])

  const { query } = useRouter()
  const { apiLocale, routeLocale } = useLocale()

  const srpUrl = getSrpComposedUrl(query)

  const {
    data: srpData,
    loading: srpLoading,
    called: srpCalled,
    client,
    loadingFilters,
  } = useGetSearchResultsData({
    fetchPolicy: 'cache-first',
    onError: () => {
      setHasClientFetchAttemptError(true)
      setLoadingStates(false)

      // errored from network request, not the cache
      client.writeQuery({
        query: GetPendingSearchInputDocument,
        data: { srp: { pendingSearchInput: null } },
      })
    },
    skip: hasClientFetchAttemptError,
  })

  const isInitialLoad = !srpData && srpLoading

  const { searchQuery, results, pagination } = srpData || {}

  const category = getSearchCategoryFromSearchQuery(searchQuery?.category, apiLocale)

  const currentPage = pagination ? getCurrentPageFromPagination(pagination) : 1
  const totalPageCount = pagination ? Math.ceil(pagination.totalCount / pagination.limit) : 1
  const listings = results?.mainListings || []
  const clusters = results?.clusters || []
  const topListings = results?.topListings || []

  const textJsonPromise = useMemo(() => {
    return getSrpContentfulData({ languageKey: routeLocale, url: srpUrl })
  }, [routeLocale, srpUrl])

  const errors = {
    hasServerError: !!serverErrors?.hasServerError,
    hasResultsError: srpCalled && !srpLoading && srpData?.results === null,
  }

  // Memoize the transformed location to prevent unnecessary re-renders for chameleon
  const memoizedLocation = useMemo(() => {
    return getUserLocationFromSearchQuery(searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery?.location])

  useChameleon({
    categoryId: category.id,
    location: memoizedLocation,
    userAgent,
  })

  useFavouriteOnLoginCallback()
  useSaveSearchFromLogin({ searchString: srpData?.searchQuery.searchString })
  useSrpTracking()

  const { spacing } = useTheme()

  const { extendedListings } = useExtendedSearch()

  const { isScriptLoadable: isClarivoyScriptLoadable } = useClarivoy({
    listings,
    category,
    pageType: GA_PAGE_TYPE.SEARCH_RESULTS_PAGE,
  })

  const router = useRouter()
  const isMapSRPEnabledFlag = useMapSRP(category.id)
  const [shouldRenderMapSRP, setShouldRenderMapSRP] = useState(
    isMapSRPEnabledFlag && !!router.query.bb
  )

  const shouldRenderTopLeaderAd = useMemo(() => !shouldRenderMapSRP, [shouldRenderMapSRP])
  const isMobile = getUserDevice(userAgent).isPhoneOrTablet

  const isMobileMapView = isMobile && shouldRenderMapSRP
  const isDesktopMapView = !isMobile && shouldRenderMapSRP

  const renderSearchListings = (searchTitle?: string | IntlResponse) => {
    if (errors.hasResultsError) {
      return <SrpError />
    }

    if (category.id === CATEGORIES.TICKETS_CATEGORY_ID) {
      return <TicketListingCard />
    }
    const h1Text =
      searchTitle && isIntlResponse(searchTitle)
        ? t(searchTitle.intl, { ...searchTitle.params })
        : searchTitle

    return (
      <SearchList
        searchTitle={h1Text}
        extendedRadiusListings={extendedListings}
        totalPageCount={totalPageCount}
        isMobile={isMobile}
        shouldRenderMapSRP={shouldRenderMapSRP}
        isMapSRPEnabled={isMapSRPEnabledFlag}
        setShouldRenderMapSRP={setShouldRenderMapSRP}
      />
    )
  }

  const hasRefetchUrlError = false

  return (
    <>
      {isClarivoyScriptLoadable && <ClarivoyScript defer />}

      {isBuyAndSellCategory(category.id) && (
        <ProductCarouselSeoJsonLd id="product-carousel-srp" listings={listings} />
      )}

      <SeoMetadata pageLoadId={pageLoadId}>
        {(pageH1) => (
          <SearchAdsWrapper isDesktopMapView={isDesktopMapView} isMobileMapView={isMobileMapView}>
            <BaseLayout
              showSearchBar={true}
              withAuthModal={true}
              includeRUMScripts={true}
              rumPageLabel="SRP"
            >
              <ErrorBoundary fingerprintId="SRP">
                {/* If there is server-side search or client-side refetch url error */}
                {hasClientFetchAttemptError || hasRefetchUrlError ? (
                  <SrpError />
                ) : (
                  <>
                    {isMobileMapView && (
                      <Spacing pLeft={spacing.default} pRight={spacing.default}>
                        <SrpResultsSort
                          setShouldRenderMapSRP={setShouldRenderMapSRP}
                          isMapSRPEnabled={isMapSRPEnabledFlag}
                          categoryId={category.id}
                        />
                      </Spacing>
                    )}
                    <PageContainer
                      bottom={spacing.default}
                      top={spacing.default}
                      isFullMax={shouldRenderMapSRP}
                    >
                      {!isMobileMapView && <SrpBreadcrumb />}
                    </PageContainer>

                    {shouldRenderTopLeaderAd && <TopLeaderboardAdSlot isMobileForAds={isMobile} />}

                    <PageContainer
                      bottom={spacing.default}
                      top={spacing.default}
                      isFullMax={shouldRenderMapSRP}
                      isMobileMapView={isMobileMapView}
                    >
                      {!isMobileMapView && (
                        <SrpHeader
                          h1={pageH1}
                          currentPage={currentPage}
                          loading={isInitialLoad}
                          isMobile={isMobile}
                          shouldRenderMapSRP={shouldRenderMapSRP}
                          categoryId={category.id}
                        />
                      )}

                      <SrpGrid shouldRenderMapSRP={shouldRenderMapSRP}>
                        {shouldRenderMapSRP ? (
                          <MapProvider>
                            <MapSRP
                              isMobileMapView={isMobileMapView}
                              listings={listings}
                              topListings={topListings}
                              clusters={clusters}
                              currentPage={currentPage}
                            />
                          </MapProvider>
                        ) : (
                          <FiltersSidebar
                            isMobileForAds={isMobile}
                            isLoadingFilters={loadingFilters}
                            categoryId={category.id}
                            shouldRenderMapSRP={shouldRenderMapSRP}
                          />
                        )}

                        <Flex flexDirection="column" gap={spacing.default}>
                          {!isMobileMapView && (
                            <SrpResultsSort
                              setShouldRenderMapSRP={setShouldRenderMapSRP}
                              isMapSRPEnabled={isMapSRPEnabledFlag}
                            />
                          )}

                          <RadiusBoostSystemMessage />

                          {shouldRenderMapSRP ? <></> : <AdSense id="AFSTop" />}

                          {renderSearchListings(pageH1)}

                          {shouldRenderMapSRP ? null : <BaseLeaderboardAdSlot />}
                        </Flex>
                      </SrpGrid>

                      {textJsonPromise && (
                        <ErrorBoundary fingerprintId="ContentfulContent">
                          <ContentfulContent userAgent={userAgent} textJson={textJsonPromise} />
                        </ErrorBoundary>
                      )}

                      {/* <AppPenRealEstateModal categoryId={category.id} /> */}

                      {!isMobileMapView && <SearchFloatingButtons isMobileForAds={isMobile} />}
                    </PageContainer>
                  </>
                )}
              </ErrorBoundary>
            </BaseLayout>
          </SearchAdsWrapper>
        )}
      </SeoMetadata>
    </>
  )
}

const isRedirectUrl = (redirect: GetSrpPropsWithCache): redirect is PageRedirect =>
  !!(redirect as PageRedirect).redirect

export const getServerSideProps: GetServerSideProps<SearchPageServerProps> = profile<
  GetServerSideProps<SearchPageServerProps>
>('srp', 'getServerSideProps', async (context) => {
  const gSSPstart = Date.now()
  const { query, req, res } = context

  const {
    apolloClient,
    forwardedHeaders,
    basePageProps,
    locale: { cookieLocale },
  } = await initializePageServer('srp', context, [
    TRANSLATION_KEYS.SRP,
    TRANSLATION_KEYS.LISTING,
    TRANSLATION_KEYS.FEEDBACK,
    TRANSLATION_KEYS.FAVOURITES,
  ])

  const url = getSrpComposedUrl(query)

  /**
   * Queries we want to pre-fetch on the server to load asap in the page (use for the NAVIGATION_SRP experiment)
   * */
  const location = apolloClient.readQuery({ query: GetUserLocationDocument })
  const locationId = location?.userLocation.id
  const cachedLocale = apolloClient.readQuery({ query: GetUserLocaleDocument })
  const locale = getLocale(cachedLocale?.userLocale.apiLocale)

  /**
   * When the URL doesn't contain the location information
   * then use the user preferences to execute the SRP/BRP search
   * AND url gets updated to match the location search
   */
  const locationlessRedirect = await profile(
    'srp',
    'redirectLocationlessUrl',
    redirectLocationlessUrl
  )({ url, cookies: req.cookies })

  if (locationlessRedirect) {
    return { redirect: locationlessRedirect.redirect }
  }

  // Fetch the Firebase decision for the NAVIGATION_SRP experiment
  try {
    if (basePageProps.machId) {
      const navigationSrpDecision =
        basePageProps?.firebaseConfig?.toggles[RemoteParamKeys.NAVIGATION_SRP].enabled ?? false

      if (navigationSrpDecision) {
        await profile(
          'srp',
          'getMenuPrefetch',
          getMenuPrefetch
        )({ apolloClient, cookieLocale, locationId })
      }
    }
  } catch (err) {
    sendToLogger(err, { fingerprint: ['getMenuPrefetchSrp'] })
  }

  const srpPropsOrRedirect = await profile(
    'srp',
    'getSrpLoadData',
    getSrpLoadData
  )({
    apolloClient,
    basePageProps,
    requestHeaders: { headers: forwardedHeaders, cookies: req.cookies },
    url,
    apiLocale: locale.apiLocale,
  })

  if (isRedirectUrl(srpPropsOrRedirect)) {
    return { redirect: srpPropsOrRedirect.redirect }
  }

  debugServerTime('props', res, gSSPstart)

  return { props: srpPropsOrRedirect }
})

export default SearchPage
