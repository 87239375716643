import { SortDirection, SrpSortBy } from '@kijiji/generated/graphql-types'
import { SortIcon } from '@kijiji/icons/src/icons'
import { colors } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'
import { useEffect, useRef, useState } from 'react'

import {
  MobileSortTextLinkButton,
  SrpSortContainer,
} from '@/components/srp/results-sort/sort/styled'
import { LatestSearchCookieRegistry } from '@/constants/cookieRegistry'
import { type SortByKeys, SORT_BY_KEYS, SORT_BY_OPTIONS } from '@/constants/search'
import { isSortByDropdownValueValid } from '@/domain/srp/isSortByDropdownValueValid'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchActions } from '@/hooks/srp/useSearchActions'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'
import { Badge } from '@/ui/atoms/badge'
import { type SelectItem, Select, SelectVariant } from '@/ui/molecules/select'
import { updateLatestSearchCookie } from '@/utils/cookies/latestSearchCookie'
import { capitalizeFirstWord } from '@/utils/string'

export const SrpSort = ({
  variant = SelectVariant.DEFAULT,
  isMobile = false,
}: {
  variant?: SelectVariant
  isMobile?: boolean
}) => {
  const { data } = useGetSearchResultsData()
  const { refetchResults } = useSearchActions()

  const srpControlsMwebToggle = useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)

  const sorting = data?.controls.sorting || []
  const currentSort = sorting.find((sort) => sort.isSelected)

  const { by: currentSortBy, direction: currentSortDirection } = currentSort || {
    by: SrpSortBy.Date,
    direction: SortDirection.Desc,
  }

  // craft the sort key from the current selected sort data
  const currentSortKey = `${currentSortBy.toLowerCase()}${capitalizeFirstWord(
    currentSortDirection
  )}`
  // intialize a ref to save the currentSortKey state used to compare with local state and data updates
  const lastKnownSortKeyRef = useRef(currentSortKey)
  // local component state for selected sort key for immediate UX changes
  const [selectedSortKey, setSelectedSortKey] = useState<string | undefined>(currentSortKey)

  useEffect(() => {
    // Check if currentSortKey has changed
    if (currentSortKey !== lastKnownSortKeyRef.current) {
      // If the new current sort key is different from the selected sort key, update it
      if (currentSortKey !== selectedSortKey) {
        setSelectedSortKey(currentSortKey)
      }
      // Update the ref with the new current sort key
      lastKnownSortKeyRef.current = currentSortKey
    }
  }, [currentSortKey, selectedSortKey])

  // Update the 'sf' cookie with the latest search value passed by Anvil
  // TODO: Why is this here? Should be handled somewhere more universal
  updateLatestSearchCookie({ [LatestSearchCookieRegistry.SORT_KEY]: currentSortKey })

  const { t } = useTranslation('srp')

  const onSelectChange = (item?: SelectItem) => {
    if (!item?.value || !isSortByDropdownValueValid(item.value)) return

    const selectedSortValue = item.value as SortByKeys

    if (selectedSortValue === currentSortKey) return

    setSelectedSortKey(selectedSortValue)

    const selectedSortByOption = SORT_BY_OPTIONS[selectedSortValue]

    refetchResults({
      by: selectedSortByOption.sort,
      direction: selectedSortByOption.order,
    })
  }

  /**
   * Map options to the format that DropdownItem expects
   *  */
  const options = sorting.reduce((acc: SelectItem[], curr) => {
    const item: SelectItem = {
      label: curr.label,
      value: `${curr.by.toLowerCase()}${capitalizeFirstWord(curr.direction)}`,
    }

    // TODO: Remove this once this feature is no longer new
    // Temporary feature requested for KJCA-421
    if (item.value === SORT_BY_KEYS.MATCH_DESC) {
      item.badge = (
        <Badge
          backgroundColor={colors.grey.primary}
          borderRadius="small"
          color={colors.white}
          label={t('sort_dropdown.new_badge.label')}
          weight="medium"
        />
      )
    }

    return [...acc, item]
  }, [])

  if (srpControlsMwebToggle?.enabled && isMobile) {
    return (
      <SrpSortContainer>
        <Select
          id="srp-sort"
          selectedValue={selectedSortKey}
          options={options}
          label={t('sort_dropdown.label')}
          onSelectChange={onSelectChange}
          data-testid="search-sort-dropdown"
          variant={variant}
          customTrigger={
            <MobileSortTextLinkButton variant="secondary" size="small" aria-label="More Options">
              <SortIcon color={colors.purple.primary} size="xsm" />
              {t('sort_dropdown.label')}
            </MobileSortTextLinkButton>
          }
        />
      </SrpSortContainer>
    )
  }
  return (
    <SrpSortContainer>
      <Select
        id="srp-sort"
        selectedValue={selectedSortKey}
        options={options}
        label={t('sort_dropdown.label')}
        onSelectChange={onSelectChange}
        data-testid="search-sort-dropdown"
        leftIcon={variant === SelectVariant.ROUNDED ? <SortIcon /> : undefined}
        variant={variant}
      />
    </SrpSortContainer>
  )
}
