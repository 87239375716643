import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgMinStay: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.05 18.75L16.8 16.5c-.15-.133-.23-.283-.237-.45a.655.655 0 01.187-.475c.133-.15.283-.22.45-.212.167.008.325.079.475.212L19.1 17V5.175c0-.184.067-.339.201-.463a.676.676 0 01.477-.187c.184 0 .338.062.461.187.124.124.186.279.186.463V16.95l1.4-1.4c.167-.167.325-.25.475-.25.15 0 .308.083.475.25.167.167.25.33.25.488 0 .158-.083.32-.25.487l-2.2 2.225a1.05 1.05 0 01-.764.325c-.29 0-.544-.108-.76-.325zm-10.783-7.3a3.39 3.39 0 002.479-1.022 3.363 3.363 0 001.03-2.478v-2.1h-7v2.111c0 .96.34 1.78 1.02 2.464a3.36 3.36 0 002.471 1.025zm-3.442 6.7h6.95v-2.086c0-.96-.343-1.785-1.03-2.476-.685-.692-1.511-1.038-2.478-1.038-.966 0-1.781.345-2.445 1.035-.665.69-.997 1.52-.997 2.49v2.075zm-1.35 0v-2.075c0-.967.258-1.854.775-2.662.517-.809 1.258-1.28 2.225-1.413-.983-.117-1.73-.583-2.237-1.4a5.009 5.009 0 01-.763-2.7V5.85h-.65c-.1 0-.182-.05-.25-.151-.066-.1-.1-.276-.1-.525 0-.183.067-.34.2-.474a.65.65 0 01.475-.2H13.4c.183 0 .34.08.474.239.134.158.201.413.201.762a.34.34 0 01-.35.349h-.6V7.9c0 1-.267 1.908-.8 2.725-.533.817-1.3 1.275-2.3 1.375 1 .117 1.767.58 2.3 1.387.533.809.8 1.705.8 2.688v2.075h.6c.1 0 .182.05.25.151.066.1.1.276.1.525 0 .183-.067.34-.2.474a.65.65 0 01-.475.2H3.15a.603.603 0 01-.474-.238c-.134-.16-.2-.413-.2-.763a.34.34 0 01.35-.349h.65z"
        fill="currentColor"
      />
    </Icon>
  )
}
export default SvgMinStay
