import { isAnyRealEstateCategory } from '@kijiji/category'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { SrpHeaderContainer, SrpHeaderSearchTitle } from '@/components/srp/header/styled'
import { SaveSearchGuestModal, SaveSearchToggle } from '@/components/srp/save-search'
import { LoadingSaveSearchToggle } from '@/components/srp/srp-loading/LoadingSaveSearchToggle'
import { LoadingSrpHeader } from '@/components/srp/srp-loading/LoadingSrpHeader'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSaveSearchActions } from '@/hooks/useSaveSearchActions'
import { RemoteParamKeys, useExperiment, useToggle } from '@/lib/firebase/hooks'
import { type IntlResponse, isIntlResponse } from '@/types/translations'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

const SaveSearchFloatingButton = dynamic(
  () =>
    import('../save-search/SaveSearchFloatingButton').then((mod) => mod.SaveSearchFloatingButton),
  { ssr: false }
)

const QuickFilters = dynamic(() =>
  import('@/features/filters/components/QuickFilters/QuickFilters').then((mod) => mod.QuickFilters)
)

/**
 * Top of the search page
 */

export type SrpHeaderProps = {
  h1: string | IntlResponse
  currentPage: number
  loading: boolean
  isMobile?: boolean
  shouldRenderMapSRP: boolean
  categoryId?: number
}

export const SrpHeader = ({
  h1,
  currentPage,
  loading = true,
  isMobile,
  shouldRenderMapSRP,
  categoryId = 0,
}: SrpHeaderProps) => {
  const { t } = useTranslation(['srp', 'filters'])
  const { colors, spacing } = useTheme()
  const { data } = useGetSearchResultsData()
  const { searchQuery } = data ?? {}
  const { data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)

  const saveSearchWithinFiltersDecision = useToggle(RemoteParamKeys.SAVED_SEARCH_WITHIN_FILTERS)

  const srpControlsMwebToggle = useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)
  const quickFilterExperiment = useExperiment(RemoteParamKeys.QUICK_FILTER_RE_TEST)
  const { enabled: isQuickFilterEnabled, group: quickFilterGroup } = quickFilterExperiment ?? {}

  const areQuickFilterPillsActive =
    (isQuickFilterEnabled === true &&
      quickFilterGroup === 1 &&
      isAnyRealEstateCategory(categoryId)) ||
    shouldRenderMapSRP
  const shouldDisplayPageNumber = currentPage > 1

  const h1Text = isIntlResponse(h1) ? t(h1.intl, { ...h1.params }) : h1

  const keywords = searchQuery?.keywords ?? ''
  const searchString = searchQuery?.searchString ?? ''
  const {
    handleActivateSaveSearch,
    isSearchSaved,
    lookupLoading,
    activateError,
    deactivateError,
    handleCloseModal,
    isModalOpen,
    signInRedirectUrl,
  } = useSaveSearchActions({
    searchString,
  })
  // Determine whether to show SaveSearchToggle
  const showSaveSearchToggle =
    !(userId && saveSearchWithinFiltersDecision?.enabled) && !shouldRenderMapSRP

  return (
    <>
      {loading ? (
        <LoadingSrpHeader />
      ) : (
        <SrpHeaderContainer>
          <>
            <SrpHeaderSearchTitle
              aria-live="polite"
              color={colors.grey.primary}
              data-testid="srp-header-title"
              size="medium"
            >
              {h1Text}
              {shouldDisplayPageNumber &&
                t('header.results.title.page_number', { pageNumber: currentPage })}
            </SrpHeaderSearchTitle>
            <Flex gap="20px" justifyContent="flex-end"></Flex>
          </>
        </SrpHeaderContainer>
      )}

      {areQuickFilterPillsActive || srpControlsMwebToggle?.enabled ? (
        <QuickFilters
          isMobile={isMobile}
          isQuickFilterReExperimentActive={areQuickFilterPillsActive}
          isSrpControlsMobileToggleEnabled={srpControlsMwebToggle?.enabled}
        />
      ) : (
        <ShowAtOrLarger breakpoint="medium">
          <Divider bottom={spacing.default} top={spacing.default} />
        </ShowAtOrLarger>
      )}

      <Spacing mBottom="0" mTop={spacing.default} large={{ mTop: '0', mBottom: spacing.default }}>
        {loading ? (
          <LoadingSaveSearchToggle />
        ) : (
          <>
            <SaveSearchGuestModal
              error={!!activateError || !!deactivateError}
              handleCloseModal={handleCloseModal}
              isModalOpen={isModalOpen}
              keywords={keywords}
              signInRedirectUrl={signInRedirectUrl}
            />
            {!lookupLoading &&
              !isSearchSaved &&
              !shouldRenderMapSRP &&
              (!saveSearchWithinFiltersDecision?.enabled || !userId) && (
                <ShowAtOrLarger breakpoint="large">
                  <Flex justifyContent="center">
                    <SaveSearchFloatingButton handleActivateSaveSearch={handleActivateSaveSearch} />
                  </Flex>
                </ShowAtOrLarger>
              )}

            <Flex justifyContent="space-between">
              {showSaveSearchToggle && <SaveSearchToggle />}
            </Flex>
          </>
        )}
      </Spacing>
    </>
  )
}
