/**
 * Remote Configurations - Custom JSON Configurations for Dynamic Settings
 *
 * Use this object to define all custom JSON configurations used in the application.
 * Each configuration must follow the `RemoteConfigType` structure.
 *
 * Example:
 * ```
 * PREPOPULATED_MESSAGES: {
 *   key: 'config_prepopulated_messages',
 * }
 * ```
 * **Key Guidelines:**
 * - `key`: Must start with the prefix `config_` followed by a descriptive name for the configuration.
 *
 * **Use Cases:**
 * - Prepopulated data sets (e.g., messages, form fields, defaults).
 * - Dynamic application settings or structured configuration objects.
 * - Large structured objects that need to be managed without deploying code changes.
 */
import { type RemoteConfigType } from '@/lib/firebase/types'

export const RemoteConfigs = {
  VIP_ROLLOUT_CATEGORIES: {
    key: 'config_vip_rollout_categories',
  },
  VIP_ROLLOUT_CATEGORY_IDS: {
    key: 'config_vip_rollout_category_ids',
  },
} as const satisfies RemoteConfigType
