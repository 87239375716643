import { Dialog } from '@reach/dialog'
import styled from 'styled-components'

import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { ResponsiveProp } from '@/ui/typings/helpers'

type StyledModalProps = {
  $height: ResponsiveProp<string>
  $isDarkModal?: boolean
  $margin: ResponsiveProp<string>
  $maxHeight?: ResponsiveProp<string>
  $maxWidth?: ResponsiveProp<string>
  $padding: ResponsiveProp<string>
  $width: ResponsiveProp<string>
  $overflow?: string
}

const getResponsiveWidth = (value: StyledModalProps['$width']) => `
  width: ${value};
`
const getResponsiveHeight = (value: StyledModalProps['$height']) => `
  height: ${value};
`
const getResponsiveMaxHeight = (value: StyledModalProps['$maxHeight']) => `
  max-height: ${value};
`
const getResponsiveMaxWidth = (value: StyledModalProps['$maxWidth']) => `
  max-width: ${value};
`
const getResponsivePadding = (value: StyledModalProps['$padding']) => `
  padding: ${value};
`
const getResponsiveMargin = (value: StyledModalProps['$margin']) => `
  margin: ${value};
`

export const StyledModal = styled(Dialog)<StyledModalProps>(
  ({
    theme,
    $height,
    $isDarkModal,
    $margin,
    $maxHeight = '',
    $maxWidth = '',
    $padding,
    $width,
    $overflow,
  }) => `
  ${applyResponsiveStyle($width, theme, getResponsiveWidth)}
  ${applyResponsiveStyle($padding, theme, getResponsivePadding)}
  ${applyResponsiveStyle($maxWidth, theme, getResponsiveMaxWidth)}
  ${applyResponsiveStyle($maxHeight, theme, getResponsiveMaxHeight)}
  ${applyResponsiveStyle($margin, theme, getResponsiveMargin)}
  ${applyResponsiveStyle($height, theme, getResponsiveHeight)}
    
  background: ${$isDarkModal ? 'rgba(47, 47, 47, 0.99)' : theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  box-shadow: ${theme.boxShadow['shadow-2']};
  display: flex;
  flex-direction: column;
  overflow: ${$overflow};
  position: relative;

  & > button {
    z-index: 1;
  }
`
)

export const StyledModalCloseButton = styled.button<{ isDarkModal?: boolean }>(
  ({ theme, isDarkModal }) => `
  align-items: center;
  background-color: ${isDarkModal ? 'transparent' : theme.colors.grey.light4};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  height: ${theme.spacing.xLarge};
  justify-content: center;
  position: absolute;
  right: 1.1rem;
  top: 1.1rem;
  width: ${theme.spacing.xLarge};

  &:hover {
    background-color: ${isDarkModal ? 'transparent' : theme.colors.grey.light3};
  }
  
  & > svg {
    color:  ${isDarkModal ? theme.colors.white : 'current'};
    height: ${isDarkModal ? theme.spacing.giant : theme.spacing.large};
    width: ${isDarkModal ? theme.spacing.giant : theme.spacing.large};
  }
`
)

export const StyledModalHeader = styled.header`
  padding: ${({ theme }) => theme.spacing.default} 0;
`

export const StyledModalBody = styled.div`
  overflow: auto;
`

export const StyledModalFooter = styled.footer`
  padding-top: ${({ theme }) => theme.spacing.default};
`
