import { type FC, type ReactNode, useCallback, useState } from 'react'
import { AdvertisingProvider } from 'react-advertising'

import { AdvertisingScripts } from '@/components-page/srp/advertisement/AdvertisingScripts'
import { useSrpAds } from '@/components-page/srp/advertisement/useSrpAds'
import { prebidConfig } from '@/features/advertisement/components/prebid/prebidConfig'

type SearchAdsWrapperProps = {
  children: ReactNode
  isDesktopMapView: boolean
  isMobileMapView: boolean
}

export const SearchAdsWrapper: FC<SearchAdsWrapperProps> = ({
  children,
  isDesktopMapView,
  isMobileMapView,
}) => {
  const [isGptReady, setIsGptReady] = useState(false)
  const { srpGptConfig } = useSrpAds(isDesktopMapView, isMobileMapView)
  const handleGptReady = useCallback(() => {
    setIsGptReady(true)
  }, [])

  return (
    <>
      <AdvertisingScripts handleGptReady={handleGptReady} />

      <AdvertisingProvider config={isGptReady ? srpGptConfig : null} plugins={prebidConfig}>
        {children}
      </AdvertisingProvider>
    </>
  )
}
