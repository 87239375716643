/**
 * Remote Toggles Configuration - Feature Toggling, Gradual Rollouts, and Permanent Toggles
 *
 * Use this object to define all feature toggles used in the application.
 * Each toggle must follow the `RemoteToggleType` structure.
 *
 * Example:
 * ```
 * ONTARIO_HEADER: {
 *   key: 'toggle_ontario_header',
 *   trackingKey: 'kjca420',
 *   defaultValue: {
 *     int: true,
 *     prod: false,
 *   },
 * }
 * ```
 * **Key Guidelines:**
 * - `key`: Must start with the prefix `toggle_` followed by a descriptive name for the feature flag.
 * - `trackingKey`: A key prefixed by `kjca` used for tracking. Should align with story JIRA ticket. Optional for Toggles.
 * - `defaultValue`: An object defining the default/fallback values for both integration (`int`) and production (`prod`) environments.
 */
import { type RemoteToggleType } from '@/lib/firebase/types'

export const RemoteToggles = {
  CHAMELEON_TRACKING: {
    key: 'toggle_chameleon_tracking',
    defaultValue: { int: true, prod: true },
  },
  MAP_SRP: {
    key: 'toggle_map_srp',
    defaultValue: { int: false, prod: false },
    trackingKey: 'kjca2003',
  },
  NAVIGATION_SRP: {
    key: 'toggle_navigation_srp',
    defaultValue: { int: false, prod: false },
  },
  PRICEDROP_NOTIFICATION: {
    key: 'toggle_pricedrop_notification',
    defaultValue: { int: false, prod: false },
  },
  ONE_TIME_SAVED_SEARCH: {
    key: 'toggle_one_time_saved_search',
    defaultValue: { int: true, prod: true },
  },
  SEARCH_NEARBY_LOCATION: {
    key: 'toggle_search_nearby_location',
    defaultValue: { int: false, prod: false },
  },
  SIMILAR_LISTINGS_MODAL: {
    key: 'toggle_similar_listings_modal',
    defaultValue: { int: false, prod: false },
  },
  SRP_QUICK_FILTERS: {
    key: 'toggle_srp_quick_filters',
    defaultValue: { int: false, prod: false },
  },
  SAVED_SEARCH_WITHIN_FILTERS: {
    key: 'toggle_saved_search_within_filters',
    defaultValue: { int: false, prod: false },
  },
  VIP_ADVERTISEMENTS: {
    key: 'toggle_vip_advertisements',
    defaultValue: { int: false, prod: false },
  },
  AUTOS_LANDING_PAGE_LINK: {
    key: 'toggle_autos_landing_page_link',
    defaultValue: { int: false, prod: false },
  },
  SRP_CONTROLS_MWEB_REDESIGN: {
    key: 'toggle_srp_controls_mweb_redesign',
    defaultValue: { int: false, prod: false },
  },
  VIP_REQUEST_VIEWING: {
    key: 'toggle_vip_request_viewing',
    defaultValue: { int: false, prod: false },
  },
} as const satisfies RemoteToggleType
