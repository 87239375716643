import { isAnyRealEstateCategory } from '@kijiji/category'
import { theme } from '@kijiji/theme'
import { Flex, Spacing } from '@kijiji/ui'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useState } from 'react'

import { SrpResults } from '@/components/srp/results-sort/results'
import {
  SaveSearchWrapper,
  SrpResultsSortContainer,
  SrpSortContainer,
} from '@/components/srp/results-sort/styled'
import { SaveSearchChipButton } from '@/components/srp/save-search-chip'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { MapViewButton } from '@/features/map/components/map-view-button/MapViewButton'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useSearchActions } from '@/hooks/srp'
import { RemoteParamKeys, useExperiment, useToggle } from '@/lib/firebase/hooks'
import { trackEvent } from '@/lib/ga'
import { GA_SAVE_SEARCH_TYPES } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { VisuallyHidden } from '@/ui/atoms/visually-hidden'
import { SelectVariant } from '@/ui/molecules/select'
import { getUserDevice } from '@/utils/getUserDevice'

const SrpSort = dynamic(() => import('./sort').then((mod) => mod.SrpSort), { ssr: false })

export type SrpResultsSortProps = {
  setShouldRenderMapSRP?: React.Dispatch<React.SetStateAction<boolean>>
  isMapSRPEnabled?: boolean
  isMobile?: boolean
  categoryId?: number
}

export const SrpResultsSort = ({
  setShouldRenderMapSRP,
  isMobile = false,
  isMapSRPEnabled = false,
  categoryId = 0,
}: SrpResultsSortProps) => {
  const router = useRouter()
  const [buttonType, setButtonType] = useState<'map' | 'list'>(router.query.bb ? 'list' : 'map')
  const { location: userLocation } = useGetLocation()
  const { refetchResults } = useSearchActions()

  const isMobileDevice = getUserDevice().isPhone
  const srpControlsMwebToggle =
    useToggle(RemoteParamKeys.SRP_CONTROLS_MWEB_REDESIGN)?.enabled ?? false
  const isSrpControlsMwebToggleEnabled = srpControlsMwebToggle && isMobileDevice
  const quickFilterExperiment = useExperiment(RemoteParamKeys.QUICK_FILTER_RE_TEST)
  const { enabled: isQuickFilterEnabled, group: quickFilterGroup } = quickFilterExperiment ?? {}

  const areQuickFilterPillsActive =
    (isQuickFilterEnabled === true &&
      quickFilterGroup === 1 &&
      isAnyRealEstateCategory(categoryId)) ||
    isSrpControlsMwebToggleEnabled

  const saveSearchWithinFiltersDecision = useToggle(RemoteParamKeys.SAVED_SEARCH_WITHIN_FILTERS)
  const { status: userSessionStatus } = useSession()

  const isSaveSearchBtnEnabled =
    saveSearchWithinFiltersDecision?.enabled &&
    isUserAuthenticated(userSessionStatus) &&
    isMobileDevice

  const handleTrackEventCTA = (type: 'List' | 'Map') => {
    trackEvent({ action: GA_EVENT.PageViewSwitchSuccess, label: `cta=${type}` })
  }

  useEffect(() => {
    setButtonType(router.query.bb ? 'list' : 'map')
  }, [router.query])

  const handleRenderMapView = useCallback(() => {
    if (buttonType === 'list') {
      refetchResults({
        location: {
          id: userLocation.id,
          area: userLocation.area,
        },
      })
    }
    setShouldRenderMapSRP?.((oldState) => !oldState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShouldRenderMapSRP, buttonType])

  return (
    <>
      <VisuallyHidden>
        {buttonType === 'list' && <SrpResults showOnLargeBreakpoint />}
      </VisuallyHidden>

      <SrpResultsSortContainer
        flexDirection="row"
        isSaveSearchActive={
          !!saveSearchWithinFiltersDecision?.enabled && isUserAuthenticated(userSessionStatus)
        }
      >
        {isSaveSearchBtnEnabled && (
          <SaveSearchWrapper justifyContent="flex-start">
            <SaveSearchChipButton
              isPillVariant={areQuickFilterPillsActive}
              isSrpView
              eventTrackingSource={GA_SAVE_SEARCH_TYPES.btn}
            />
          </SaveSearchWrapper>
        )}
        {buttonType === 'map' && <SrpResults showOnLargeBreakpoint />}
        <Flex
          flexGrow={1}
          gap={theme.spacing.defaultSmall}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {isMapSRPEnabled && (
            <Spacing
              mTop={theme.spacing.default}
              large={{
                mTop: '0',
              }}
            >
              <MapViewButton
                onClick={() => {
                  handleTrackEventCTA(buttonType === 'map' ? 'Map' : 'List')
                  handleRenderMapView()
                }}
                type={buttonType}
              />
            </Spacing>
          )}
          {isMapSRPEnabled && isMobile ? (
            <></>
          ) : (
            <SrpSortContainer
              isRoundedSelect={false}
              isSrpControlsMwebToggleEnabled={isSrpControlsMwebToggleEnabled}
            >
              <SrpSort
                variant={
                  areQuickFilterPillsActive || setShouldRenderMapSRP
                    ? SelectVariant.ROUNDED
                    : SelectVariant.DEFAULT
                }
                isMobile={isMobileDevice}
              />
            </SrpSortContainer>
          )}
        </Flex>
      </SrpResultsSortContainer>
    </>
  )
}
