export const MAP_ZOOM_CONTROL = {
  WORLD: 1,
  CONTINENT: 5,
  CITY: 10,
  STREETS: 15,
  BUILDINGS: 20,
} as const

export const MAX_SRP_ZOOM_DESKTOP = 17

export const MAX_SRP_ZOOM_MWEB = 15
